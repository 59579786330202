import * as React from "react";

function SvgMenuWhite(props) {
    return (
        <svg width={28} height={18} fill="none" {...props}>
            <path d="M0 18h28v-3H0v3zm0-7.5h28v-3H0v3zM0 0v3h28V0H0z" fill="#fff" />
        </svg>
    );
}

export default SvgMenuWhite;
