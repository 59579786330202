//warnings

import { maximumAmountOfNightMinutesBySchedule } from "../utils/functions";
import getTransformedValue from "../utils/getTransformedValue";

export const FAILED_REQUEST_WARNING =
    "No se pudo conectar al servidor. Por favor intente en unos minutos";
export const INVALID_CONTRACT_CONDITION =
    "Los trabajadores con contratos definidos deben tener una fecha de salida que sea mayor a la fecha de ingreso";
export const REQUEST_ERROR_DEFAULT_MESSAGE = "Fallo en la petición";

export const FAILED_LOGIN_WARNING = FAILED_REQUEST_WARNING;

export const FAILED_LOGOUT_WARNING =
    "Fallo al cerrar sesión. Por favor inténtelo de nuevo";

export const INVALID_EMAIL_ON_LOGIN =
    "Ingrese un correo electrónico válido antes de continuar";

export const INVALID_PASSWORD_ON_LOGIN =
    "Ingrese una contraseña válida antes de continuar";
export const MAX_AMOUNT_OF_TIME =
    "El periodo excede la cantidad máxima de horas posibles";
export const INVALID_SCHEDULES_WARNING = "Los horarios escogidos deben ser válidos";
export const NO_REST_DAYS_WARNING = "Ingrese los días de descanso";
export const NOT_ALL_DAYS_SELECTED = "Ingrese todos los días";

export const PASSWORDS_ARE_NOT_EQUAL = "Las contraseñas no coinciden";

export const CHECK_ITEM_BEFORE_REQUEST = "Seleccione un pago antes de continuar";

export const TOTAL_WORKED_DAYS_MESSAGE =
    "El total entre días trabajados y días descansados debe ser igual a ";

//schedules
export const TOO_MANY_HOURS_WARNING = "Error: too many hours";

export const OVERLAPING_PERIODS_WARNING =
    "Error: Los periodos no pueden ser concurrentes";

export const MINIMUM_PERIODS_WARNING =
    "Una asistencia debe tener al menos un periodo. Para marcar una inasistencia presione Marcar Inasistencia";

export const DAY_SCHEDULE_INCLUDES_NIGHT_HOURS =
    "Un horario diurno no puede incluir horas nocturnas";

export const TOO_MANY_PERIODS_WARNING =
    "Un horario solo puede tener 5 períodos como máximo";

export const MIXED_SCHEDULE_HAS_MAXIMUM_AMOUNT_OF_NIGHT_HOURS =
    "Un horario mixto solo puede tener 3 horas nocturnas";
export const DAY_SCHEDULE_HAS_MAXIMUM_AMOUNT_OF_NIGHT_HOURS =
    "Un horario diurno solo puede tener 4 horas nocturnas";
export const NIGHT_SCHEDULE_HAS_MAXIMUM_AMOUNT_OF_DAY_HOURS =
    "Un horario nocturno solo puede tener 3 horas diurnas";

export const SCHEDULE_HAS_MAXIMUM_AMOUNT_OF_NIGHT_HOURS = (scheduleType, minutesAmount) =>
    `Un horario ${getTransformedValue(scheduleType, "scheduleTypeM")} solo puede tener ${
        minutesAmount / 60
    } horas nocturnas `;

export const INVALID_DURATION_WARNING =
    "El horario seleccionado tiene una duración inválida por el horario y turno del trabajador";

export const INVALID_DAY_SCHEDULE =
    "Un horario nocturno solo puede tener 3 horas diurnas";
//Success

export const SUCESSFULL_REVISED_ID_REQUEST = "Pago aprobado";

export const ADDED_TRANSFER = "Transacción guardada  ";

export const SAVED_WORKER_ATTENDANCE_SUCCESS = "Asistencia guardada";

export const SAVED_WORKER_INATTENDANCE_SUCCESS = "Inasistencia guardada";

export const SAVED_PAYROLL_SUCCESSS = "Nómina creada con éxito";

export const INVALID_NIGHT_HOURS = (schedule_type) =>
    `Un horario  ${getTransformedValue(
        schedule_type,
        "scheduleTypeM"
    )} solo puede tener  ${
        maximumAmountOfNightMinutesBySchedule(schedule_type) / 60
    } horas nocturnas`;
