import moment from "moment";
import { getConstantsValues, getConstantsKeys, looper } from "./functions";

//###Transform constants

export const TODAY = moment();
export const TODAY_UNIX = TODAY.format("x");
export const currentYear = moment().format("YYYY");
export const currentYearInt = +currentYear;

//export const CLOTHING = ["S", "M", "L", "M", "XL"];
export const tomorrow = TODAY.add(1, "days").format("YYYY-MM-DD");
export const PAYMENT_STATUS = [
    "pending",
    "revise",
    "aproved",
    "paid",
    "finished"
    // null means Not checked yet
];

export const WORKER_STATUS = ["Activo", "Vacación", "Permiso", "Inactivo"];
export const WORKER_STATUS_ENGLISH = ["active", "holiday", "leave", "inactive"];

export const BANK_ACCOUNTS_TYPES = ["Corriente", "Ahorro"];
/* export const REQUESTS_TYPES = ["Nómina", "Vacaciones", "Utilidades", "Adicional"]; */
export const PERSONAL_RELATIONSHIP = [
    "Padre",
    "Hijo",
    "Abuelo",
    "Hermano",
    "Primo",
    "Otro"
];
export const STATUS_CLASS = ["active", "holiday", "leave", "inactive"];
export const CONTRACT_CONDITION = ["Determinada", "Indeterminada", "A destajo"];

export const BLOOD_TYPE = ["A", "B", "AB", "O"];
export const GENDER = ["Masculino", "Femenino", "Otro"];
export const SCHEDULE_TYPE = ["Diurna", "Nocturna", "Mixta"];
export const SCHEDULE_TYPE_M = SCHEDULE_TYPE.map((v) => {
    //FIX FIX
    const [last, ...word] = [...v].reverse();
    return word.reverse().join("") + "o";
});
export const CLOTHING = ["XXS", "XS", "S", "M", "L", "XL", "XXL"];
export const SHOE_SIZES = looper(35, 50);
export const SHIFT_TYPE = ["Tiempo completo", "Tiempo parcial"];
export const REQUEST_STATUS = ["PENDING", "TO_REVIEW", "APPROVED", "PAID", "CLEARED"];
export const PAYMENT_PERIOD_TYPE = ["Diaria", "Semanal", "Quincenal"];
export const CIVIL_STATUS = ["Soltero", "Casado", "Divorciado", "Viudo"];
export const LANGUAGE_ID = ["Inglés", "Francés", "Italiano"];
export const acceptedImageFiles = ["image/png", "image/jpeg"];
export const acceptedTextFiles = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
];
export const ACCEPTED_TEXT_FILES = ["PDF", "DOC", "DOCX"];
export const ACCEPTED_IMAGE_FILES = ["PNG", "JPEG"];
export const ACCEPTED_TEXT_FILES_FORMATS = ACCEPTED_TEXT_FILES.join(", ");
export const ACCEPTED_IMAGE_FILES_FORMATS = ACCEPTED_IMAGE_FILES.join(", ");
export const LANGUAGE_PROFICIENCY = [
    "Principiante",
    "Básico",
    "Intermedio",
    "Intermedio-Alto",
    "Avanzado",
    "Avanzado"
];
export const DOMINANT_HAND = ["Diestro", "Zurdo", "Ambiediestro"];
export const payrollItemName = {
    AWD: "Días trabajados",
    ARD: "Días descansados",
    AWRD: "Días de descanso laborados",
    AWH: "Días feriados laborados",
    AWHC: "Cargo por feriado laborado",
    AWSC: "Cargo por domingo laborado",
    A30: "Treintava Art LOTTT",
    AW31: "Día 31 laborado",
    AEDH: "Horas extra diurnas",
    AENH: "Horas extra nocturnas",
    ANB: "Bono nocturno",
    ACT: "Cesta ticket de alimentación",
    DSS: "IVSS",
    DFU: "Paro forzoso",
    DFAOV: "FAOV",
    DSA: "Anticipo Salarial",
    DUA: "Falta injustificada",
    DLRD: "Días de descanso por falta",
    DNPL: "Permiso no remunerado",
    DAH: "Horas faltadas"
};
export const PAYROLL_ITEM_UNIT_TYPE = ["horas", "días", "%", "Bs. s"];
export const SCHEDULES = ["DAY_SCHEDULE", "NIGHT_SCHEDULE", "MIXED_SCHEDULE"];

//##Values
export const workerStatus = getConstantsValues(WORKER_STATUS);
export const bankAccountType = getConstantsValues(BANK_ACCOUNTS_TYPES);
export const personalRelationship = getConstantsValues(PERSONAL_RELATIONSHIP);
//export const requestType = getConstantsValues(REQUESTS_TYPES);
export const statusClass = getConstantsValues(STATUS_CLASS);
export const contractCondition = getConstantsValues(CONTRACT_CONDITION);
export const bloodType = getConstantsValues(BLOOD_TYPE);
export const gender = getConstantsValues(GENDER);
export const scheduleType = getConstantsValues(SCHEDULE_TYPE);
export const scheduleTypeM = getConstantsValues(SCHEDULE_TYPE_M);

export const clothing = getConstantsValues(CLOTHING);
export const shiftType = getConstantsValues(SHIFT_TYPE);
export const requestStatus = getConstantsValues(REQUEST_STATUS);
export const paymentPeriodType = getConstantsValues(PAYMENT_PERIOD_TYPE);
export const civilStatus = getConstantsValues(CIVIL_STATUS);
export const languageId = getConstantsValues(LANGUAGE_ID);
export const languageProficiency = getConstantsValues(LANGUAGE_PROFICIENCY);
export const dominantHand = getConstantsValues(DOMINANT_HAND);
//export const payrollItemUnitType = getConstantsValues(PAYROLL_ITEM_UNIT_TYPE);

//##Keys
export const dominantHandKeys = getConstantsKeys(DOMINANT_HAND);
export const clothingKeys = getConstantsKeys(DOMINANT_HAND);
export const aceptedTextFilesFormatsKeys = getConstantsKeys(acceptedTextFiles);
export const aceptedImageFormatsKeys = getConstantsKeys(acceptedImageFiles);
export const paymentStatusKeys = getConstantsKeys(PAYMENT_STATUS);
//## Single constants
export const MAX_WEIGHT = 250; //kg
export const MAX_HEIGHT = 250; //cm
export const MIN_WORKER_AGE = 15;
export const MINIMUM_CAR_YEAR = 1964;
export const MAXIMUM_CAR_YEAR = Number(TODAY.format("YYYY")) + 2;

export const FULL_SHIFT = 0;
export const PARTIAL_SHIFT = 1;
export const DAY_SCHEDULE = 0;
export const NIGHT_SCHEDULE = 1;
export const MIXED_SCHEDULE = 2;

export const MAX_REST_HOURS = 2;
export const MAX_HOURS_REGULAR = 8;
export const MAX_HOURS_FULL_SHIFT_DAY_SCHEDULE = 8;
export const MAX_HOURS_FULL_SHIFT_NIGHT_SCHEDULE = 7;
export const MAX_HOURS_FULL_SHIFT_MIXED_SCHEDULE = 7;
export const MAX_HOURS_FULL_SHIFT_NIGHT_OR_MIXED_SCHEDULE = 7;
export const MAX_TOTAL_HOURS_REGULAR = MAX_HOURS_REGULAR + MAX_REST_HOURS;
export const MAX_HOURS_PARTIAL_SHIFT_DAY_SCHEDULE = 5;
export const MAX_HOURS_PARTIAL_SHIFT_NIGHT_SCHEDULE = 4;
export const MAX_HOURS_PARTIAL_SHIFT_MIXED_SCHEDULE = 4;
export const MAX_HOURS_PARTIAL_SHIFT_NIGHT_OR_MIXED_SCHEDULE = 4;
export const MAX_HOURS_PARTIAL_SHIFT = 4;

// ##### Payments
export const PAYMENT_TO_REVIEW = 1;
export const APPROVED_PAYMENT = 2;

//ndata
export const MAXIMUM_FILE_SIZE = 2000000; // 2mb or 2000000 bytes

export const MAX_FILE_SIZE_MESSAGE = "2MB";
export const INVALID_FILE_MESSAGE = (v) =>
    `El archivo debe estar en uno de los siguientes formatos: ${v} y ser menor a ${MAX_FILE_SIZE_MESSAGE}`;

export const ACCEPTED_TEXT_FILES_MESSAGE = INVALID_FILE_MESSAGE(
    ACCEPTED_TEXT_FILES_FORMATS
);
export const ACCEPTED_IMAGE_FILES_MESSAGE = INVALID_FILE_MESSAGE(
    ACCEPTED_IMAGE_FILES_FORMATS
);
export const ACCEPTED_TEXT_AND_IMAGE_FILES_MESSAGE = INVALID_FILE_MESSAGE(
    `${ACCEPTED_TEXT_FILES_FORMATS}, ${ACCEPTED_IMAGE_FILES_FORMATS}`
);

export const paymentPeriodsSelectData = [
    /*     {
            value: 0,
            label: "Diario"
        }, */
    {
        value: 1,
        label: "Quincenal"
    },
    {
        value: 2,
        label: "Mensual"
    }
];
