import React from "react";
import ArrowLeft from "../../media/icons/ArrowLeft";

const CenterCardArrow = (props) => {
    const { showArrow, goBack } = props;
    return (
        <div className="center-card-return-arrow">
            {showArrow && (
                <div>
                    <ArrowLeft onClick={goBack} />{" "}
                </div>
            )}
        </div>
    );
};

export default CenterCardArrow;
