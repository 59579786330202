import React from "react";
import HoursIInputsItem from "./HoursInputsItem";
import { numberToOrdinal } from "../../utils/functions";
import SvgAdd from "../../media/icons/Add";

const HoursInputs = (props) => {
    const {
        setScheduleWeek,
        setSchedule,
        addItemCallback,
        contractCondition,
        scheduleType,
        shiftType,
        hoursInputsValues
    } = props;
    const items = new Array(hoursInputsValues).fill(null).map((elem, index) => (
        <HoursIInputsItem
            //   values={hoursInputsValues[index]}
            contractCondition={contractCondition}
            scheduleType={scheduleType}
            setSchedule={setSchedule}
            setScheduleWeek={setScheduleWeek}
            itemLabel={`${numberToOrdinal(index + 1)} horario`}
            index={hoursInputsValues.length + 1}
            shiftType={shiftType}
        />
    ));

    return items;
};

export default HoursInputs;
