import React from "react";
import Refresh from "../../../media/icons/Refresh";
import "../../../styles/CSS/global-components/request-error.css";
import { REQUEST_ERROR_DEFAULT_MESSAGE } from "../../../text-constants";
import Exclamation from "../../../media/icons/Exclamation";

/**
 * Returns an error message and a button to restart the request
 *
 * @param {*} props
 */

const RequestError = (props) => {
    const {
        errorMessage,
        buttonCallback,
        containerClassName,
        errorData: { message, status }
    } = props;

    return (
        <div className={`request-error ${containerClassName}`}>
            <div className="request-error-icon-container">
                <Exclamation />
            </div>
            {status !== 999 && (
                <h5 className="request-error-code-text">
                    <mark className="bold">Codigo: </mark>

                    {status}
                </h5>
            )}

            <h3 className="request-error-main-text">
                {status === 500 ? "Fallo del servidor" : message || errorMessage}
            </h3>
            <div className="reques-error-btn-container">
                <button
                    type="text"
                    className="request-error-btn btn btn_primary"
                    onClick={buttonCallback}
                >
                    <Refresh />
                </button>
            </div>
        </div>
    );
};

export default RequestError;

RequestError.defaultProps = {
    buttonText: "reintentar",
    errorData: {
        status: 0,
        message: REQUEST_ERROR_DEFAULT_MESSAGE
    },
    errorMessage: REQUEST_ERROR_DEFAULT_MESSAGE,
    buttonCallback: "FORGOT TO PASS CALLBACK",
    containerClassName: ""
};
