import { uniqueId } from "../../utils/functions";
import { FAILED_REQUEST_WARNING } from "../../text-constants";

export const defaultFailedRequestMessage = FAILED_REQUEST_WARNING;

export const popNotification = (payload, notificationType = "info") => {
    return {
        type: "POP_NOTIFICATION",
        text: payload,
        id: uniqueId(),
        notificationType,
        elementType: "text"
    };
};

export const popNotificationSpinner = (payload) => {
    return { type: "POP_NOTIFICATION_SPINNER", elementType: "spinner", id: uniqueId() };
};
