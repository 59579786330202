import React, { Fragment } from "react";
import MainContainer from "./views/app-main/MainContainer";
import CenterCard from "./views/center-card/CenterCard";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Ajax from "./utils/Ajax";
import getBaseUrl from "./config/env";
import { connect } from "react-redux";
import {
    persistentLogin,
    stopLoading,
    loginUserSuccess
} from "./redux/actions/userSessionActions";
import "simplebar/dist/simplebar.min.css";
import { userSession } from "./endpoints";
import LoaderSmall from "./global-components/loader/LoaderSmall";
import ModalPortal from "./global-components/modal/ModalPortal";
import Modal from "./global-components/modal/Modal";
import PopNotifications from "./global-components/pop-notifications/PopNotifications";
import "./styles/CSS/normalize.css";
import "./styles/CSS/reset.css";
import "./styles/CSS/main.css";
import "./styles/CSS/animations.css";
import "./styles/CSS/variables.css";
import PopLegalNotifications from "./global-components/pop-notifications/PopLegalNotifications";
import "./styles/CSS/global-components/default-list-placeholder.css";

const { LOGIN_FROM_TOKEN } = userSession;

function mapStateToProps(state) {
    return {
        userSession: state.userSessionReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        persistentLogin: (localData, token) =>
            dispatch(persistentLogin(localData, token)),
        stopLoading: () => dispatch(stopLoading()),
        loginUserSuccess: (loginData) => dispatch(loginUserSuccess(loginData))
    };
};

const PrivateRoute = ({ component: Component, path, isLogged, hasBusiness, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isLogged ? (
                <Component {...props} path={path} {...rest} />
            ) : (
                <Redirect to="/" />
            )
        }
    />
);

Ajax.baseUrl = getBaseUrl();

/**
 * 
 * It will initally display a loader then it will check if there's a JWT in
 *  local storage . If it  does it will start a request to check if the session is still valid. 
 * If the request's response includes a hasBusiness key
 * it means that the user has logged and has selected a business and will render the app dashboard,
 *  it it doesn't the user logged but did not select a business it will render the BusinessSelect component.
 * 
 * App handles everything through the userSession reducer from Redux
 * 
 * FAQ
 * 
 * UserSessionReducer: 
 *  
 *  isLoading:boolean - if true it will render a page wide spinner
    isLogged:boolean 
    hasBusiness:boolean
    id(pin):4 - unique id by user
    email(pin):string 
    username(pin): string
    businesses: array of objects 
    {id : string unique id by business
name: string company name
idn:"J353009365"
laravel_through_key:?
profilePicture:profile Picture of the company. it goes in the sidebar. If null it will render the default.
isActive:boolean 
}
    type(pin): number //fix
    status(pin):number /fix
    profilePicture(pin):string // url of the user img. 
 * 
 
 Status
 * Initial render -> Spinner
 * User has not logged: Login
 * User has local Data and had selected a business -> Dashboard (or any route, it will no redirect it back to /login)
  * User has local Data and had not selected a business -> Business Select component
 *  
 * 
 * What routes are avaliable to a non logged user?
 * Those rendered by CenterCard except BusinessSelect
 * 
 * What happens if a go to "app/home" and I'm not logged?
 *  It will redirect you to Login
 * 
 * When does the App save the bearer token?
 * After the user logs in.
 * 
 * 
 *  For more information check userSessionActions
 * 
 *  
 * 
 */
class App extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            isLoading: true
        };
        this.hydrateStateWithLocalStorage = this.hydrateStateWithLocalStorage.bind(this);
        this.initialRequestWithLogin = this.initialRequestWithLogin.bind(this);
    }

    componentDidMount() {
        this.hydrateStateWithLocalStorage();
    }

    initialRequestWithLogin(currentToken) {
        const requestHeader = {
            useBaseUrl: true
        };
        const xhr = new Ajax(LOGIN_FROM_TOKEN, requestHeader);
        xhr.result()
            .then((res) => {
                this.props.loginUserSuccess({ ...res.data, token: currentToken });
            })
            .catch((err) => {
                console.log(err, "failed request");
                this.props.persistentLogin(
                    {
                        isLogged: true,
                        hasBusiness: true
                    },
                    currentToken
                );
                this.setState({ err });
                return "error";
            })
            .finally(() => this.props.stopLoading());
    }

    hydrateStateWithLocalStorage() {
        const userHasLogged =
            localStorage.mainUserData && JSON.parse(localStorage.mainUserData);

        if (userHasLogged) {
            Ajax.token = userHasLogged.currentToken;
            this.initialRequestWithLogin(userHasLogged.currentToken);
            return;
        }
        this.props.stopLoading();
        //this.setState({ isLoading: false });
    }

    render() {
        const {
            logoutUser,
            updateUserAuthInfo,
            checkJwtValidity,
            initialRequestWithLogin,
            //state: { isLoading },
            props: {
                userSession: { isLogged, hasBusiness, isLoading }
            }
        } = this;
        if (isLoading) {
            return (
                <div className="app-loading grid-center">
                    <LoaderSmall />
                </div>
            );
        }
        return (
            <Fragment>
                <ModalPortal>
                    <Modal />
                    <PopNotifications />
                    <PopLegalNotifications />
                </ModalPortal>
                <Switch>
                    <PrivateRoute
                        path="/dashboard"
                        component={MainContainer}
                        isLogged={isLogged}
                        hasBusiness={hasBusiness}
                        logoutUser={logoutUser}
                        initialRequestWithLogin={initialRequestWithLogin}
                        updateUserAuthInfo={updateUserAuthInfo}
                    />
                    <Route
                        path="/"
                        render={(props) => (
                            <CenterCard
                                hasBusiness={hasBusiness}
                                isLogged={isLogged}
                                mainUserData={this.state}
                                checkJwtValidity={checkJwtValidity}
                                {...props}
                            />
                        )}
                    />
                </Switch>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
