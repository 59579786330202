import React from "react";
import { createPortal } from "react-dom";
import "../../styles/CSS/global-components/modal.css";

const portalRoot = document.getElementsByClassName("portal-root")[0];

class ModalNew extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement("div");
    }

    componentDidMount() {
        portalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        portalRoot.removeChild(this.el);
    }

    render() {
        return createPortal(this.props.children, this.el);
    }
}
export default ModalNew;
