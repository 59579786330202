import React from "react";
//import SettingsIcon from "../../media/icons/Settings";
//import NotificationIcon from "../../media/icons/Notifications";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import { connect } from "react-redux";
import ImageContainer from "../layout/Image/ImageContainer";
import defaultImg from "../../media/images/placeholder-user.jpg";

function mapStateToProps(state) {
    return {
        userData: state.userSessionReducer
    };
}

const NavBarIconsContainer = (props) => {
    const {
        //hasNotifications,
        userData
    } = props;
    //  const hasNotificationsClass = hasNotifications ? "_active scale-in-center" : "";
    return (
        <div className="navbar-icons-container ">
            {/*   <div className="navbar-icon">
                <SettingsIcon />
            </div>
            <div className="navbar-icon-notification-container">
                <div className={`navbar-icon-notification${hasNotificationsClass}`} />
                <NotificationIcon />
            </div> */}
            <div className="navbar-icon navbar-user-img-container grid-center ">
                <Link
                    className="navbar-user-img grid-center"
                    to={{
                        pathname: `${routes.appMainPath}/${routes.userProfile}`
                    }}
                >
                    <ImageContainer
                        imgSrc={userData.profilePicture || defaultImg}
                        containerClassName="navbar-user-img-super"
                        imgClassName="navbar-user-img-img"
                    />
                </Link>
            </div>
        </div>
    );
};

export default connect(mapStateToProps)(NavBarIconsContainer);
