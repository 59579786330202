//import { modalToggle, closeModal, showSpinner } from "../actions/modalActions";

import { showInputsModalAction } from "../actions/modalActions";

const modalReducer = (
    state = { showModal: false, items: null, elementType: null, title: null },
    action
) => {
    switch (action.type) {
        case "REGISTER_WORKER":
            return {
                ...state,
                showModal: true,
                title: action.message,
                items: action.items,
                elementType: "registerWorkerLocalData"
            };
        case "TOGGLE_MODAL":
            return {
                ...state,
                showModal: true,
                elementType: action.payload.elementType,
                items: action.payload.items,
                title: action.payload.title
            };
        case "CLOSE_MODAL":
            return {
                ...state,
                showModal: false,
                elementType: action.elementType,
                items: action.items,
                title: action.title
            };
        case "SHOW_MODAL_SPINNER":
            return {
                ...state,
                elementType: "spinner",
                showModal: true
            };
        case showInputsModalAction:
            return {
                ...state,
                elementType: showInputsModalAction,
                showModal: true,
                items: null
            };
        case "ADD-SCHEDULE":
            return {
                ...state,
                elementType: "add-schedule",
                showModal: true,
                items: action.items
            };
        case "SHOW_ASSISTANCE_INPUTS":
            return {
                ...state,
                items: action.items, //this would be the worker id
                elementType: "assistanceInputs",
                showModal: true,
                title: "Ingrese ID personal"
            };

        default:
            return state;
    }
};

export default modalReducer;
