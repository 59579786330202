import * as React from "react";

function SvgExclamation(props) {
    return (
        <svg height="1em" viewBox="0 0 24 24" width="1em" {...props}>
            <path d="M0 0h24v24H0z" fill="none" />
            <circle cx={12} cy={19} r={2} />
            <path d="M10 3h4v12h-4z" />
        </svg>
    );
}

export default SvgExclamation;
