import { workers, generalEndpoints, payments } from "../../endpoints";
import { deleteKeys } from "../../utils/functions";

const initialState = {
    [workers.DINAMIC_SELECTS]: {
        ids: [],
        data: {}
    },
    [generalEndpoints.ALL_COMPANIES]: {
        ids: [],
        data: {}
    },
    [workers.WORKER_PROFILE]: {},
    [workers.WORKERS]: {},
    payrolls: {},
    [payments.PENDING_PAYMENTS]: {},
    [payments.CHART]: {
        ids: [],
        data: {}
    },
    "reusable-schedules": {
        ids: [],
        data: {}
    },
    [payments.RECEIPT]: {}
};

const requestCacheReducer = (state = initialState, action) => {
    const { endpoint, ids, additionalData, items, actualPage } = action;
    switch (action.type) {
        case "SET_CACHED_DATA":
            return {
                ...state,
                [endpoint]: {
                    endpoint,
                    ids,
                    items,
                    actualPage,
                    ...additionalData
                }
            };
        case "DELETE_CACHED_DATA":
            return {
                ...state,
                [action.endpoint]: initialState[action.endpoint]
            };
        case "SET_CACHED_DATA_BY_ID":
            const { id, data } = action;

            return {
                ...state,
                [action.endpoint]: {
                    ...state[action.endpoint],
                    [id]: {
                        ...data
                    }
                }
            };
        case "DELETE_CACHED_DATA_BY_ID":
            const cleanValue = deleteKeys(state[action.endpoint], String(action.id));
            return { ...state, [action.endpoint]: cleanValue };

        case "DELETE_ALL_CACHED_DATA":
            return {
                ...initialState,
                [generalEndpoints.ALL_COMPANIES]: state[generalEndpoints.ALL_COMPANIES],
                payrolls: {}
            };
        default:
            return state;
    }
};

export default requestCacheReducer;
