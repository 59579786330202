import React from "react";
import SideBar from "../../global-components/SideBar/SideBar";
import Navbar from "../../global-components/NavBar/NavBar";
import "../../styles/CSS/super-container.css";
import { connect } from "react-redux";
import { modalToggle } from "../../redux/actions/modalActions";
import MainRouter from "./MainRouter";
import "../../styles/CSS/payments/payments.css";
import "../../styles/CSS/global-components/control-buttons.css";

const mapDispatchToProps = (dispatch) => {
    return {
        toggleDisplay: (payload) => dispatch(modalToggle(payload))
    };
};
/**
 * Contains the components inside every view on AppMain  like the sidebar, navbar and the router.
 *
 *
 */
class MainView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSidebar: false
        };
        this.openModal = this.openModal.bind(this);
        //  this.logoutUser = this.logoutUser.bind(this);
    }
    /* componentWillUnmount(){
    localStorage.clear()
} */

    openModal({ elementType, title, items }) {
        this.props.toggleDisplay({
            elementType,
            title,
            items
        });
    }

    render() {
        const {
            openModal,
            toggleSidebar,
            props: {
                initialRequestWithLogin,
                logoutUser,
                match: { url },
                history: {
                    location: { pathname }
                }
            },
            state: { showSidebar }
        } = this;

        return (
            <div className="super-container">
                {/*     <ModalPortal>
                    <Modal />
                    <PopNotifications />
                </ModalPortal> */}
                <SideBar logoutUser={logoutUser} showSidebar={showSidebar} path={url} />
                <div className="main-area">
                    {/* <PopNotifications /> */}
                    <Navbar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />
                    <main className="main-area-section">
                        <div className="main-card">
                            <MainRouter
                                url={url}
                                initialRequestWithLogin={initialRequestWithLogin}
                                openModal={openModal}
                                pathname={pathname}
                            />
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(MainView);
