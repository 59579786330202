import { uniqueId } from "../../utils/functions";
import { FAILED_REQUEST_WARNING } from "../../text-constants";

export const defaultFailedRequestMessage = FAILED_REQUEST_WARNING;

export const popLegalNotification = (payload) => {
    return {
        type: "POP_LEGAL_NOTIFICATION",
        text: payload,
        id: uniqueId(),
        elementType: "text"
    };
};
export const resetNotification = () => {
    return {
        type: "RESET_LEGAL_NOTIFICATION",
        text: [],
        id: uniqueId(),
        elementType: "text"
    };
};
