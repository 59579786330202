const popLegalNotificationsReducer = (
    state = {
        text: [],
        elementType: "",
        id: null
    },
    action
) => {
    switch (action.type) {
        case "POP_LEGAL_NOTIFICATION":
            return {
                ...state,
                elementType: "legalNotification",
                text: [...state.text, ...action.text],
                id: action.id
            };
        case "RESET_LEGAL_NOTIFICATION":
            return {
                ...state,
                text: [],
                id: action.id
            };

        default:
            return state;
    }
};

export default popLegalNotificationsReducer;
