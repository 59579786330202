import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import thunk from "redux-thunk";
import reducers from "./redux/reducers/index";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import './trans/trans';
const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const myStore = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));

ReactDOM.render(
    <React.StrictMode>
        <Provider store={myStore}>
            <Router>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
