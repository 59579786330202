import * as React from "react";

function SvgPercentageDown(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 12 7" fill="none" {...props}>
            <path d="M1 6l5-5 5 5" stroke="#dd5f82" />
        </svg>
    );
}

export default SvgPercentageDown;
