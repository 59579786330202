import * as React from "react";

function SvgLogout(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 21" fill="none" {...props}>
            <path
                d="M11.339 13.982L9.25 11.888h9.627c.61 0 1.109-.5 1.109-1.111 0-.612-.5-1.112-1.109-1.112H9.251l2.088-2.107a1.113 1.113 0 000-1.573 1.105 1.105 0 00-1.57 0L5.79 9.983a1.113 1.113 0 000 1.574l3.987 3.999a1.105 1.105 0 001.57 0 1.131 1.131 0 00-.007-1.574zM0 2.981v15.598c0 1.222.998 2.23 2.224 2.23h15.552A2.235 2.235 0 0020 18.58v-3.342c0-.611-.5-1.112-1.109-1.112s-1.108.5-1.108 1.112v2.224c0 .61-.5 1.112-1.109 1.112H3.332c-.61 0-1.108-.501-1.108-1.112V4.093c0-.612.499-1.112 1.108-1.112h13.33c.609 0 1.108.5 1.108 1.112v2.23c0 .61.499 1.112 1.108 1.112.61 0 1.109-.501 1.109-1.112V2.98c0-1.223-.985-2.23-2.224-2.23H2.223A2.23 2.23 0 000 2.98z"
                fill="#828282"
            />
        </svg>
    );
}

export default SvgLogout;
