import React from "react";
import NavBarIconsContainer from "./NavBarIconsContainer";
//import NavBarSearchIcon from "./NavBarSearchIcon";
import "../../styles/CSS/global-components/navbar-style.css";
import Menu from "../../media/icons/MenuWhite";
//import MenuOpen from "../../media/icons/MenuOpen";
import { sidebarToggle } from "../../redux/actions/sidebarActions";

import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => {
    return {
        sidebarToggle: () => dispatch(sidebarToggle())
    };
};

const NavBar = React.memo((props) => {
    //const { showSidebar, toggleSidebar } = props;
    const { sidebarToggle } = props;

    return (
        <nav className="navbar-container">
            <span onClick={sidebarToggle} className="menu-icon">
                <Menu />
            </span>
            <NavBarIconsContainer hasNotifications={true} />
        </nav>
    );
});

export default connect(null, mapDispatchToProps)(NavBar);
