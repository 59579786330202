import React from "react";
import { Route, Switch } from "react-router-dom";
import "../../styles/CSS/center-card/center-card.css";
import CenterCardArrow from "./CenterCardArrow";
import { Redirect } from "react-router-dom";
import CenterCardAuthSwitch from "./CenterCardAuthSwitch";
import Ajax from "../../utils/Ajax";
import { popNotification } from "../../redux/actions/popNotificationsActions";
import {
    loginUserSuccess,
    loginLoading,
    loginWithBusiness
} from "../../redux/actions/userSessionActions";
import { connect } from "react-redux";
import { userSession } from "../../endpoints";
import LoaderSmall from "../../global-components/loader/LoaderSmall";
import {
    FAILED_LOGIN_WARNING,
    INVALID_EMAIL_ON_LOGIN,
    INVALID_PASSWORD_ON_LOGIN
} from "../../text-constants";
import inputValidator from "../../utils/InputValidator";
import { showSpinner, closeModal } from "../../redux/actions/modalActions";
import { capitalize } from "../../utils/functions";
const { LOGIN, SET_BUSINESS } = userSession;
/* const newBusinesses = this.props.userSession.businesses.map(elem => {
    if (elem.id === businessId){
        return {...elem, token: res.data.token, isActive: true}
    }
    return {...elem, isActive: false};
});
this.props.loginWithBusiness({token: res.data.token, newBusinesses});
}) */

function mapStateToProps(state) {
    return {
        userSession: state.userSessionReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        popNotificationWarning: (notificationText) =>
            dispatch(popNotification(notificationText, "warning")),
        loginUserSuccess: (loginData) => dispatch(loginUserSuccess(loginData)),
        loginLoading: () => dispatch(loginLoading()),
        closeModal: () => dispatch(closeModal()),
        showSpinner: () => dispatch(showSpinner()),
        loginWithBusiness: (tokenData) => dispatch(loginWithBusiness(tokenData))
    };
};

class CenterCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            businessesData: false,
            errorCode: false,
            hasError: false,
            isLoading: false
        };
        this.setLoggedUser = this.setLoggedUser.bind(this);
        this.setBussiness = this.setBussiness.bind(this);
        this.isLoginValid = this.isLoginValid.bind(this);

        // this.getBusinesses = this.getBusinesses.bind(this);
        this.loggedUserData = false;
        this.loggedUserToken = false;
    }

    isLoginValid({ email, password }) {
        const isEmailValid = inputValidator("email", email);
        const isPasswordValid = inputValidator("password", password);
        if (!isEmailValid) {
            this.props.popNotificationWarning(INVALID_EMAIL_ON_LOGIN);
            return false;
        }
        if (!isPasswordValid) {
            this.props.popNotificationWarning(INVALID_PASSWORD_ON_LOGIN);
            return false;
        }
        return true;
    }

    setLoggedUser(event, userData) {
        event.preventDefault();
        if (!this.isLoginValid(userData)) return;
        const requestHeader = {
            body: userData,
            method: "POST",
            useBaseUrl: true
        };
        const xhr = new Ajax(LOGIN, requestHeader);
        this.setState({
            isLoading: true
        });
        this.props.loginLoading();

        xhr.result()
            .then((res) => {
                this.props.loginUserSuccess(res.data);
            })
            .catch((err) => {
                console.log(err, "err");
                let errorMessage = FAILED_LOGIN_WARNING;
                if (err.error) {
                    const { message, validation_errors } = err.error;
                    console.log(err.error, "err.error");
                    if (validation_errors)
                        errorMessage = Object.values(validation_errors)[0][0];
                    if (message) errorMessage = message;
                }

                this.props.popNotificationWarning(capitalize(errorMessage));
                this.setState({
                    hasError: err.message
                });
            })
            .finally(() => this.setState({ isLoading: false }));

        this.props.loginLoading();
    }

    setBussiness(businessId) {
        //this will make a request to get the new token
        // with the selected business data
        //Ajax.token =  this.props.mainUserData.token || "";
        const requestHeader = {
            useBaseUrl: true
        };

        const xhr = new Ajax(`${SET_BUSINESS}${businessId}`, requestHeader);
        this.props.showSpinner();
        xhr.result()
            .then((res) => {
                const newBusinesses = this.props.userSession.businesses.map((elem) => {
                    if (elem.id === businessId) {
                        return { ...elem, token: res.data.token, isActive: true };
                    }
                    return { ...elem, isActive: false };
                });
                this.props.loginWithBusiness({ token: res.data.token, newBusinesses });
            })
            .catch((err) => {
                this.props.popNotificationWarning("Fallo al iniciar sesión");
            })
            .finally(() => this.props.closeModal());
    }

    render() {
        const {
            setLoggedUser,
            //    setBussiness,
            makeRequest,
            state: { errorCode, isLoading },
            props: {
                updateUserAuthInfo,
                //  mainUserData: { bizId, isLogged },
                isLogged,
                //       hasBusiness,

                history: {
                    goBack,
                    location: { pathname }
                }
            }
        } = this;
        const toggleLoading = () => this.setState({ isLoading: !this.state.isLoading });

        const routesWithoutArrow = [`/`, "/select-business"];
        const showArrow = !routesWithoutArrow.includes(pathname);
        if (isLoading) {
            return (
                <div className="center-card-container-super">
                    <LoaderSmall containerClassName="fade-in" />
                </div>
            );
        }
        if (isLogged) return <Redirect to="/dashboard" />;

        return (
            <div className="center-card-container-super">
                <CenterCardArrow goBack={goBack} showArrow={showArrow} />
                <Switch>
                    <Route
                        path={`/`}
                        setLoggedUser={setLoggedUser}
                        makeRequest={makeRequest}
                        render={(props) => (
                            <CenterCardAuthSwitch
                                updateUserAuthInfo={updateUserAuthInfo}
                                isLogged={isLogged}
                                setLoggedUser={setLoggedUser}
                                makeRequest={makeRequest}
                                errorCode={errorCode}
                                pathname={pathname}
                                toggleLoading={toggleLoading}
                            />
                        )}
                    />
                </Switch>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CenterCard);
