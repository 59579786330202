import React from "react";
import LabeledInput from "../inputs/LabeledInput";

import LabeledSelect from "../inputs/select/LabeledSelect";
import {
    getValidHoursBySchedule,
    maximumAmountOfNightMinutesBySchedule
} from "../../utils/functions";
import { MIXED_SCHEDULE, NIGHT_SCHEDULE, TODAY } from "../../utils/constants";
import {
    DAY_SCHEDULE,
    fullDateFormat,
    MAXIMUM_AMOUNT_OF_DAY_MINUTES_IN_A_NIGHT_SCHEDULE
} from "../../utils/timeConstants";
import { countPeriodDayHours, countPeriodNightHours } from "../../utils/timeFunctions";
//import restHoursSelectData from "./restHoursSelectData";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { popNotification } from "../../redux/actions/popNotificationsActions";
import { connect } from "react-redux";
//import { SCHEDULE_HAS_MAXIMUM_AMOUNT_OF_NIGHT_HOURS } from "../../text-constants";
import getTransformedValue from "../../utils/getTransformedValue";
import { Button } from "../buttons/Buttons";

const moment = extendMoment(Moment);

const mapDispatchToProps = (dispatch) => {
    return {
        popNotification: (payload, type) => dispatch(popNotification(payload, type))
    };
};

function cleanTotalHours(totalHours) {
    return totalHours % 1 === 0 ? totalHours : totalHours.toFixed(1);
}

const restHoursSelectData = new Array(8).fill(null).map((elem, index) => ({
    label: `${index + 1}h`,
    value: index + 1
}));

//let scheduleType = 0; //day
const getInvalidText = ({ isValid, scheduleType, shiftType, validHoursBySchedule }) => {
    if (isValid) return "";

    let errorText = `Un horario ${getTransformedValue(
        scheduleType,
        "scheduleTypeM"
    ).toLowerCase()} de ${getTransformedValue(
        shiftType,
        "shiftType"
    )} debe tener entre 1 y ${validHoursBySchedule} horas de duración`;
    return errorText;
};
function getScheduleTypeHours(scheduleType) {
    const obj = {
        [DAY_SCHEDULE]: {
            //day schedule
            min: "5:00",
            max: "19:00"
        },
        [NIGHT_SCHEDULE]: {
            //night schedule
            min: "19:00",
            max: "5:00"
        },
        [MIXED_SCHEDULE]: {
            //mixed schedule
            min: "02:00",
            max: "22:00"
        }
    };
    return obj[scheduleType];
}

class HoursIInputsItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rest_hours: 1,
            totalHours: null,
            isValid: null,
            start_time: "08:00",
            end_time: "17:00",
            errorText: ""
        };

        this.saveSchedule = this.saveSchedule.bind(this);
        this.setValidity = this.setValidity.bind(this);
    }

    setRealValidity() {
        const {
            props: { scheduleType, shiftType },
            state: { rest_hours, start_time, end_time }
        } = this;
        window.moment = moment;
        let today = TODAY.format("YYYY-MM-DD");

        const start = moment(start_time, "HH:mm");
        const end = moment(end_time, "HH:mm");

        if (start.isAfter(end)) end.add(1, "d");

        const startFormated = `${today} ${start_time}`;
        const endFormatted = `${today} ${end_time}`;

        const hourDifference = moment.duration(end.diff(start)).asHours() - rest_hours;

        const validHoursBySchedule = getValidHoursBySchedule(scheduleType, shiftType);
        let isValid = hourDifference <= validHoursBySchedule && hourDifference >= 1;

        let errorText = getInvalidText({
            isValid,
            scheduleType,
            shiftType,
            validHoursBySchedule
        });

        if (scheduleType === DAY_SCHEDULE || scheduleType === MIXED_SCHEDULE) {
            const x1 = start.format(fullDateFormat);
            const x2 = end.format(fullDateFormat);

            let nightime = countPeriodNightHours(x1, x2);

            const maximumAmountOfNightMinutes = maximumAmountOfNightMinutesBySchedule(
                scheduleType
            );

            if (nightime > maximumAmountOfNightMinutes) {
                if (scheduleType === DAY_SCHEDULE) {
                    errorText = `Un horario diurno no puede tener horas nocturnas`;
                } else {
                    errorText = `Un horario  ${getTransformedValue(
                        scheduleType,
                        "scheduleTypeM"
                    )} solo puede tener  ${
                        maximumAmountOfNightMinutes / 60
                    } horas nocturnas`;
                }
                isValid = false;
            }
            console.log({ nightime, isValid, maximumAmountOfNightMinutes });
        }

        if (scheduleType === NIGHT_SCHEDULE) {
            const totalDayMinutes = countPeriodDayHours(startFormated, endFormatted);

            if (totalDayMinutes > MAXIMUM_AMOUNT_OF_DAY_MINUTES_IN_A_NIGHT_SCHEDULE) {
                errorText = `Un horario nocturno solo puede tener 3 horas diurnas`;
                isValid = false;
            }

            console.log({ totalDayMinutes, isValid });
        }
        this.setState({
            totalHours: hourDifference,
            isValid,
            errorText
        });
    }

    setValidity({ value, name }) {
        //  if (scheduleType === DAY_SCHEDULE) {

        this.setState(
            {
                [name]: value
            },
            () => this.setRealValidity()
        );
        //    }
    }

    saveSchedule() {
        const {
            props: { popNotification, index, setSchedule },
            state: { rest_hours, start_time, end_time, isValid }
        } = this;

        if (isValid === false) {
            popNotification("Horario Inválido", "warning");
            return;
        }
        popNotification("Horario Guardado");
        setSchedule({
            index,
            rest_hours,
            end_time: `${end_time}`,
            start_time: `${start_time}`
        });
    }

    render() {
        const {
            setValidity,
            saveSchedule,
            state: { isValid, start_time, rest_hours, totalHours, errorText, end_time },
            props: { scheduleType, index }
        } = this;
        return (
            <div
                className={`schedule-picker-hours-inputs-item schedule-picker-hours-inputs-item_${
                    isValid ? "valid" : "invalid"
                }`}
            >
                <label className="schedule-picker-hours-inputs-item-title">
                    {isValid !== null &&
                        (isValid ? (
                            <mark className="sucess-text sucess"> Horario válido</mark>
                        ) : (
                            <mark className="invalid-text"> Horario inválido</mark>
                        ))}
                </label>

                <div className="schedule-picker-hours-inputs-item-container flex-col-align-space-around">
                    <div className="schedule-picker-hours-inputs-hours">
                        <LabeledInput
                            label="Hora de inicio"
                            type="time"
                            name="start_time"
                            layoutSpacingClassName="worker-schedule-input-item"
                            index={index}
                            value={start_time}
                            validationType="_noValidation"
                            // isDisabled={!!!!!!restHoursValues || !Boolean(rest_hours)}
                            //min="12:00"
                            //isDisabled={!restHoursValues && !rest_hours}

                            containerClassName="schedule-picker-start-hours-item"
                            //   callback={setSchedule}
                            callback={setValidity}
                            {...getScheduleTypeHours(scheduleType)}

                            //    callback={({ value }) => console.log(value)}
                        />

                        <LabeledInput
                            label="Hora de Salida"
                            type="time"
                            min="12:00"
                            name="end_time"
                            index={index}
                            layoutSpacingClassName="worker-schedule-input-item"
                            containerClassName="schedule-picker-end-hours-item"
                            validationType="_noValidation"
                            callback={setValidity}
                            value={end_time}
                            {...getScheduleTypeHours(scheduleType)}
                            /* callback={({ value }) => console.log(value)} */
                        />
                        <LabeledSelect
                            label="Horas de descanso"
                            name="rest_hours"
                            value={rest_hours}
                            //isDisabled={!Boolean(start_time)}
                            index={index}
                            selectData={restHoursSelectData}
                            callback={setValidity}
                            containerClassName="schedule-picker-rest/hours-item"
                            layoutSpacingClassName="worker-schedule-input-item"
                        />
                    </div>
                    {!!totalHours && (
                        <label>
                            Horas totales diarias: {cleanTotalHours(totalHours)}h
                        </label>
                    )}
                    {errorText && (
                        <label className="hours-inputs-item-error-label">
                            <mark className="bold red">Advertencia:</mark> {errorText}{" "}
                        </label>
                    )}
                </div>

                <div className="modal-register-worker-local-data-button">
                    <Button
                        className="modal-register-worker-local-data-inner-btn modal-add-schedule-btn"
                        text="Guardar"
                        callback={saveSchedule}
                        type={isValid || isValid === null ? "primary" : "disabled"}
                    />
                </div>
            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(HoursIInputsItem);

HoursIInputsItem.defaultProps = {
    values: {
        end_time: "",
        start_time: "",
        rest_hours: ""
    }
};
