import Ajax from "../../utils/Ajax";
import setLocalStorageToken from "../../utils/functions";

export const actions = {
    LOGIN_USER: "LOGIN_USER",
    LOADING: "LOADING",
    LOGIN_WITH_BUSINESS: "LOGIN_WITH_BUSINESS",
    LOGOUT: "LOGOUT",
    PERSISTENT_LOGIN: "PERSISTENT_LOGIN",
    MANTAIN_LOCAL_TOKEN: "MANTAIN_LOCAL_TOKEN",
    STOP_LOADING: "STOP_LOADING",
    CHANGE_COMPANY: "CHANGE_COMPANY"
};

export function loginUserSuccess(loginData) {
    const {
        token,
        user: { current_business, ...userData }
    } = loginData;
    Ajax.token = token;
    setLocalStorageToken(token);
    return { type: actions.LOGIN_USER, userData, current_business };
}

export function loginLoading() {
    return { type: actions.LOADING };
}
export function changeCompany(companyData) {
    return { type: actions.CHANGE_COMPANY, companyData };
}

export function loginWithBusiness(payload) {
    Ajax.token = payload.token;
    setLocalStorageToken(payload.token);
    return { type: actions.LOGIN_WITH_BUSINESS, payload };
}

export function logout() {
    Ajax.token = "";
    localStorage.clear();
    return { type: actions.LOGOUT };
}

export function persistentLogin(localStorageData, token) {
    Ajax.token = token;
    return { type: actions.PERSISTENT_LOGIN, payload: localStorageData };
}

export function mantainLocalToken(localToken) {
    Ajax.token = localToken;
    setLocalStorageToken(localToken);

    return { type: actions.MANTAIN_LOCAL_TOKEN, payload: localToken };
}
export function stopLoading() {
    return { type: actions.STOP_LOADING };
}
