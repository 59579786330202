export const setDinamicData = (payload) => {
    return { type: "SET_REGISTER_WORKER_DINAMIC_SELECT_DATA", payload };
};

export const setDinamicCity = (cityValue, cities) => {
    return { type: "SET_REGISTER_WORKER_DINAMIC_SELECT_CITIES_DATA", cityValue, cities };
};

export const resetData = () => {
    return { type: "RESET_DINAMIC_SELECTS_DATA" };
};
