import { lazy } from "react";
import EditWorkerSchedule from "../views/app-main/workers/edit-worker-schedule/EditWorkerSchedule";

/* import Login from "../views/center-card/Login";
import Register from "../views/center-card/Register"; */

/* 
import ForgotPassword from "../views/center-card/ForgotPassword";
import RecoverPasswordSuccess from "../views/center-card/RecoverPasswordSuccess";

import RecoverPassword from "../views/center-card/RecoverPassword";
import ChangePassword from "../views/app-main/change-password/ChangePassword"; */

const Login = lazy(() => import("../views/center-card/Login"));
const Register = lazy(() => import("../views/center-card/Register"));

const ForgotPassword = lazy(() => import("../views/center-card/ForgotPassword"));

const RecoverPasswordSuccess = lazy(() =>
    import("../views/center-card/RecoverPasswordSuccess")
);

const RecoverPassword = lazy(() => import("../views/center-card/RecoverPassword"));

const EditWorker = lazy(() =>
    import("../views/app-main/workers/register-worker1/edit-worker/EditWorker")
);

const RegisterWorkerRequestWrapper = lazy(() =>
    import("../views/app-main/workers/register-worker1/RegisterWorkerRequestWrapper")
);

const ManualPaymentRequestWrapper = lazy(() =>
    import("../views/app-main/payments/manual-payment/ManualPaymentRequestWrapper")
);
const WorkerAssistanceRequestWrapper = lazy(() =>
    import("../views/app-main/calendar/RequestWrapper")
);
const ChangePassword = lazy(() =>
    import("../views/app-main/change-password/ChangePassword")
);

const AddPayroll = lazy(() =>
    import("../views/app-main/payments/add-payroll/AddPayroll")
);
const EditReceipt = lazy(() =>
    import("../views/app-main/payments/edit-receipt/EditReceipt")
);
const NoRoute = lazy(() => import("../views/app-main/404"));

//const AddNewPayroll = lazy(() => import("../views/app-main/config/Configuration"));

const UserProfile = lazy(() => import("../views/app-main/user-profile"));

const AllCompanies = lazy(() =>
    import("../views/app-main/companies/AllCompaniesRequestWrapper")
);
/* const RecentRequests = lazy(() =>
    import("../views/app-main/payments/request-table/RequestTable")
); */
const AddCompany = lazy(() => import("../views/app-main/companies/AddCompany"));

const Calendar = lazy(() => import("../views/app-main/calendar/Calendar"));
const AllPayments = lazy(() =>
    import("../views/app-main/payments/all-payments/AllPayments")
);
const PendingRequests = lazy(() =>
    import("../views/app-main/payments/request-table/PendingRequestsWrapper")
);
const RecentRequests = lazy(() =>
    import("../views/app-main/payments/request-table/RecentRequestRequestWrapper")
);

const Receipt = lazy(() => import("../views/app-main/payments/receipt/Receipt"));
const Home = lazy(() => import("../views/app-main/home/Home"));
const Request = lazy(() => import("../views/app-main/requests/Requests"));

const PayrollInfo = lazy(() =>
    import("../views/app-main/workers/payroll-info/PayrollInfo")
);
const WorkerProfile = lazy(() =>
    import("../views/app-main/workers/worker-profile/WorkerProfile")
);
/* const PendingPayments = lazy(() =>
    import("../views/app-main/payments/pending-payments/PendingPayments")
);
 */

const Dashboard = lazy(() => import("../views/app-main/payments/dashboard/"));

/* const PendingRequests = lazy(() =>
    import("../views/app-main/payments/request-table/PendingRequestsWrapper")
); */

const RecentPaymentsById = lazy(() =>
    import("../views/app-main/payments/recent-payments/RecentPaymentsById")
);
const PendingPaymentsById = lazy(() =>
    import("../views/app-main/payments/pending-payments/PendingPaymentsById")
);

const PayrollConfig = lazy(() =>
    import("../views/app-main/payments/payroll-config/PayrollConfig")
);

const NewPayroll = lazy(() =>
    import("../views/app-main/payments/new-payroll/NewPayroll")
);
const AllWorkers = lazy(() => import("../views/app-main/workers/all-workers/AllWorkers"));

const workerRoute = "workers";
const requestRoute = "requests";
const paymentsRoute = "payments";
const companiesRoute = "companies";

export const routes = {
    appMainPath: "/dashboard",
    home: "/",
    register: "/register",
    restorePassword: "/restaurar-contraseña",
    companies: `${companiesRoute}/`,
    addCompany: `${companiesRoute}/add-company`,
    allCompanies: `${companiesRoute}/all-companies`,
    forgotPassword: "/forgot-password",
    recoverPassword: "/recover-password",
    recoverPasswordSuccess: "/recover-password-success",
    selectBusiness: "/select-business",
    manualPayment: `${paymentsRoute}/manual-payment`,
    requestsMain: `${requestRoute}/`,
    workersMain: `${workerRoute}/`,
    calendarRoute: `${workerRoute}/calendar`,
    registerWorker: `${workerRoute}/register-worker`,
    payrollInfo: `${workerRoute}/payroll-info`,
    workerAsisstances: `${workerRoute}/assistances`,
    recentWorkerPayments: `${workerRoute}/recent-payments`,
    workerProfile: `${workerRoute}/worker-profile`,
    allWorkers: `${workerRoute}/all-workers`,
    editWorker: `${workerRoute}/edit-worker`,
    recentPayments: `${paymentsRoute}/recent-payments`,
    recentRequests: `${paymentsRoute}/recent-requests`,
    /* pendingRequests: `${paymentsRoute}/pending-requests`, */
    pendingPayments: `${paymentsRoute}/pending-payments`,
    allPayments: `${paymentsRoute}/all-payments`,
    newPayroll: `${paymentsRoute}/new-payroll`,
    payrollMain: `${paymentsRoute}/payroll-main`,
    payrollConfig: `${paymentsRoute}/payroll-config`,
    receipt: `${paymentsRoute}/receipt`,
    editReceipt: `${paymentsRoute}/edit-receipt`,
    payments: `${paymentsRoute}/`,
    userProfile: "user",
    paymentsMain: `${paymentsRoute}/dashboard`,
    changePassword: "change-password"
};

const appMainRoutes = {
    centerCard: {
        public: [
            {
                path: routes.home,
                exact: true,
                component: Login
            },
            {
                path: routes.register,
                exact: true,
                component: Register
            },
            {
                path: routes.forgotPassword,
                exact: true,
                component: ForgotPassword
            },
            {
                path: routes.restorePassword,
                exact: false,
                component: RecoverPassword
            },
            {
                path: routes.recoverPasswordSuccess,
                exact: true,
                component: RecoverPasswordSuccess
            },
            {
                //redirect to login on 404
                path: "",
                exact: false,
                component: Login
            }
        ]
    },
    appMain: [
        //requests
        {
            path: routes.requestsMain,
            exact: true,
            component: Request
        },

        //Workers
        {
            path: routes.workersMain,
            exact: true,
            component: AllWorkers
        },
        {
            path: routes.registerWorker,
            exact: true,
            component: RegisterWorkerRequestWrapper
        },
        {
            path: routes.editWorker,
            exact: false,
            component: EditWorker
        },
        {
            path: routes.payrollInfo,
            exact: true,
            component: PayrollInfo
        },
        /*    {
            path: routes.recentWorkerPayments,
            exact: true,
            component: RecentPayments
            //this used to be the all recent payments view. Is deprecated
        }, */
        {
            path: routes.workerProfile,
            //exact: true,
            component: WorkerProfile
        },
        {
            path: routes.allWorkers,
            exact: true,
            component: AllWorkers
        },
        {
            path: routes.workerAsisstances,
            exact: false,
            component: WorkerAssistanceRequestWrapper
        },

        /// payments
        {
            path: routes.recentPayments,
            exact: true,
            component: RecentRequests
        },
        {
            path: routes.pendingPayments,
            exact: true,
            component: PendingRequests
        },
        {
            path: routes.recentPayments,
            exact: false,
            component: RecentPaymentsById
        },
        {
            path: routes.pendingPayments,
            exact: false,
            component: PendingPaymentsById
        },
        // {
        //     path: routes.recentRequests,
        //     exact: true,
        //     component: RecentRequests
        // },
        // {
        //     path: routes.pendingRequests,
        //     exact: true,
        //     component: PendingRequests
        // },
        {
            path: routes.allPayments,
            exact: true,
            component: AllPayments
        },
        {
            path: routes.newPayroll,
            exact: true,
            component: NewPayroll
        },
        {
            path: routes.payrollMain,
            exact: true,
            component: AddPayroll
        },
        {
            path: routes.payrollConfig,
            exact: true,
            component: PayrollConfig
        },
        {
            path: routes.receipt,
            exact: false,
            component: Receipt
        },
        {
            path: routes.editReceipt,
            exact: false,
            component: EditReceipt
        },
        {
            path: routes.payments,
            exact: true,
            component: Dashboard
        },
        {
            path: routes.paymentsMain,
            exact: true,
            component: Dashboard
        },
        {
            path: routes.calendarRoute,
            exact: true,
            component: Calendar
        },
        {
            path: routes.addCompany,
            exact: true,
            component: AddCompany
        },
        {
            path: routes.allCompanies,
            exact: true,
            component: AllCompanies
        },
        {
            path: routes.companiesRoute,
            exact: true,
            component: AllCompanies
        },
        {
            path: routes.manualPayment,
            exact: true,
            component: ManualPaymentRequestWrapper
        },
        {
            path: routes.userProfile,
            exact: true,
            component: UserProfile
        },
        {
            path: "",
            exact: true,
            component: Home
        },
        {
            path: `edit-schedule`,
            exact: false,
            component: EditWorkerSchedule
        },
        {
            path: routes.changePassword,
            exact: true,
            component: ChangePassword
        },
        {
            path: "",
            exact: false,
            component: NoRoute //404
        }
    ]
};

export default appMainRoutes;
