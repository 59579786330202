import React from "react";
import { Button } from "../../../../global-components/buttons/Buttons";

const ButtonContainer = (props) => {
    const {
        containerClassName,
        buttonFullWidth,
        buttonClassName,
        buttonText,
        buttonCallback
    } = props;
    return (
        <div className={containerClassName}>
            <Button
                type="success"
                className={buttonClassName}
                fullWidth={buttonFullWidth}
                text={buttonText}
                callback={buttonCallback}
            />
        </div>
    );
};

ButtonContainer.defaultProps = {
    buttonClassName: "",
    containerClassName: "",
    buttonFullWidth: false,
    text: "You forgot to add the button text",
    buttonCallback: () => console.log("you forgot to add the callback")
};
export default ButtonContainer;
