import React from "react";
import defaultImg from "../../media/images/default-company-img.png";
import { connect } from "react-redux";
import ImageContainer from "../layout/Image/ImageContainer";
import { generalEndpoints } from "../../endpoints";
import Ajax from "../../utils/Ajax";
import { showSpinner, closeModal } from "../../redux/actions/modalActions";
import inputValidator, { invalidInputText } from "../../utils/InputValidator";
import {
    popNotification,
    defaultFailedRequestMessage
} from "../../redux/actions/popNotificationsActions";

const { CHANGE_COMPANY_IMG } = generalEndpoints;
function mapStateToProps(state) {
    return {
        businesses: state.userSessionReducer.businesses
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        popNotification: (notificationText, type) =>
            dispatch(popNotification(notificationText, type)),
        showSpinner: () => dispatch(showSpinner()),
        closeModal: () => dispatch(closeModal())
    };
};

class SideBarCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToHome: false,
            newImg: null
        };
        this.newImg = null;
        this.changeImage = this.changeImage.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.businesses.id !== prevProps.businesses.id) {
            this.setState({ newImg: null });
        }
    }

    changeImage({ value: picture }) {
        const {
            popNotification,
            showSpinner,
            closeModal,
            businesses: { id }
        } = this.props;

        if (!picture) return;

        if (!inputValidator("imageFile", picture)) {
            popNotification(`${invalidInputText.imageFile}`, "warning");
            return;
        }
        showSpinner();
        const xhr = new Ajax(CHANGE_COMPANY_IMG, {
            useBaseUrl: true,
            method: "POST",
            body: {
                new_business_id: id,
                picture
            }
        });
        xhr.result()
            .then(() => {
                this.setState({
                    newImg: URL.createObjectURL(picture)
                });
                popNotification(`El archivo se ha subido con éxito ${picture.name}`);
            })
            .catch((c) => {
                console.log(c);
                popNotification(defaultFailedRequestMessage);
            })
            .finally(() => closeModal());
    }

    render() {
        const {
            state: { newImg },
            changeImage,
            props: {
                businesses: { profilePicture, name }
            }
        } = this;
        return (
            <div className="sidebar-company">
                <div
                    className="sidebar-company-container fade-in"
                    title="Cambiar imagen de empresa"
                >
                    <input
                        title="Cambiar imagen de empresa"
                        type="file"
                        multiple={false}
                        className="sidebar-company-img-input"
                        onChange={(e) => changeImage({ value: e.target.files[0] })}
                    />
                    <ImageContainer
                        containerClassName="sidebar-company-img-container"
                        altText={`Logo de ${name}`}
                        imgSrc={newImg || profilePicture || defaultImg}
                        imgClassName="sidebar-company-img"
                    />

                    <div className="sidebar-company-name-container grid-center">
                        <h3 className="sidebar-company-name"> {name}</h3>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarCompany);

SideBarCompany.defautProps = {
    businesses: "null"
};
