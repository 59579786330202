const endpoints = {
    requests: {
        USER_REQUESTS: "user-requests",
        APPROVED_REQUESTS_IDS: "approve-payments",
        REVIEW_PAYMENTS: "review-payments"
    },
    home: {
        USER_REQUESTS: "user-requests",
        WORKERS: "workers",
        CHART: "payment-chart-data",
        PENDING_PAYMENTS: "payment-requests-variation?pending=1"
    },
    userSession: {
        LOGIN: "login",
        SET_BUSINESS: "login-business?business_id",
        LOGIN_FROM_TOKEN: "login-from-token",
        LOGOUT: "logout"
    }
};

export const requests = {
    USER_REQUESTS: "user-requests",
    REVIEW_PAYMENTS: "review-payments",
    APPROVED_REQUESTS_IDS: "approve-payments",
    PENDING_PAYMENTS_APPROVED_REQUESTS_IDS: "mark-paid"
};

export const business = {
    registerBusiness: "register-business",
    registerBusinessData: "store-data",
    changeBusiness: "login-business?business_id="
};

export const userSession = {
    LOGIN: "login",
    SET_BUSINESS: "login-business?business_id=",
    LOGIN_FROM_TOKEN: "login-from-token",
    LOGOUT: "logout",
    CHANGE_PASSWORD: "change-password"
};

export const workers = {
    WORKERS: "workers",
    REGISTER_WORKER: "register-worker",
    DINAMIC_SELECTS: "registry-data",
    REGISTER_WORKER_FILES: "update-user-files",
    WORKER_PROFILE: "workers",
    ATTENDANCE: "store-attendance",
    SCHEDULE: "store-schedule",
    CREATE_ABSENCE: "delete-attendance",
    CREATE_NEW_ASSISTANCE: "manual-attendance",
    WORKER_ASSISTANCE: "attendances",
    UPDATE_ASSISTANCE: "update-attendance",
    ADD_PERIOD: "work_lapses",
    EDIT_WORKER: "update-worker"
};

export const payments = {
    CHART: "payment-chart-data",
    PENDING_PAYMENTS: "payment-requests-variation?pending=1",
    RECENT_PAYMENTS: "payment-requests-variation?recent=1",
    RECEIPT: "payments", // `payments/${paymentId}`
    ALL_PAYMENTS: "payments",
    BANK_ACCOUNTS: "bank-accounts",
    WORKER_BANK_ACCOUNTS: "bank-accounts", //bank-accounts/${workerId}
    TRANSACTIONS: "transactions"
};

export const generalEndpoints = {
    CITIES: "cities?state_id=",
    CHANGE_COMPANY_IMG: "update-business-picture",
    ALL_COMPANIES: "user-businesses"
};

export const userAccount = {
    FORGOT_PASSWORD: "forgot-password",
    CHANGE_COMPANY_IMG: "update-business-picture",
    RESET_PASSWORD: "reset-password"
};

export default endpoints;
