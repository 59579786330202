import { actions } from "../actions/currentRequestActions";
const { GET_CURRENT_REQUEST, RESET_CURRENT_REQUEST } = actions;

const currentRequestReducer = (currentRequest = { id: null, date: null }, action) => {
    switch (action.type) {
        case GET_CURRENT_REQUEST:
            return { id: action.id, date: action.date };

        case RESET_CURRENT_REQUEST:
            return { id: null, date: null };
        default:
            return currentRequest;
    }
};

export default currentRequestReducer;
