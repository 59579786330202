import React from "react";
import RequestError from "../layout/request-error";

const DefaultError = ({ errorData, buttonCallback, layoutClassName }) => (
    <RequestError
        errorData={errorData}
        buttonCallback={buttonCallback}
        containerClassName={`${layoutClassName} grid-center `}
    />
);

export default DefaultError;
