import en from './en';
import es from './es';

let lang = 'es';

const languages = {
    en,
    es
};

/**
 * returns a message based on the 
 * current language of the 
 * application
 * @param {string} translation Message path separated by dots
 * @param {object} attributes Attributes to be translated
 */
export function trans(translation, attributes = {}) {
    let split = translation.split('.');
    let message = languages[lang];

    for (let index = 0; index < split.length; index++) {
        message = message[split[index]];
        if (!message) return translation;
    }

    Object.keys(attributes).forEach((attr) => {
        message = message.replace(`:${attr}`, attributes[attr]);
    });

    // console.log(message);

    return message;
}

/**
 * Set the application language
 * @param {'es'|'en'} language 
 */
export function setLanguage(language) {
    lang = languages.hasOwnProperty(languages) ? language : 'es';
}

// console.log(trans("example", { field: "patoson" }))

window.__trans = trans;
