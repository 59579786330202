const popNotificationsReducer = (
    state = {
        text: "",
        elementType: null,
        id: null
    },
    action
) => {
    switch (action.type) {
        case "POP_NOTIFICATION":
            const { notificationType, text, id } = action;
            return {
                ...state,
                elementType: "text",
                text,
                id,
                notificationType
            };
        case "POP_NOTIFICATION_SPINNER":
            return {
                ...state,
                elementType: "spinner",
                id: action.id,
                text: "",
                notificationType: ""
            };
        default:
            return state;
    }
};

export default popNotificationsReducer;
