export const actions = {
    GET_CURRENT_REQUEST: "GET_CURRENT_REQUEST",
    RESET_CURRENT_REQUEST: "RESET_CURRENT_REQUEST"
};

export const getCurrentRequest = (payload) => ({
    type: actions.GET_CURRENT_REQUEST,
    id: payload.id,
    date: payload.date
});

export const resetCurrentRequest = () => ({
    type: actions.RESET_CURRENT_REQUEST
});
