import React from "react";
import People from "../../media/icons/People";
//import Folder from "../../media/icons/Folder";
import Expenses from "../../media/icons/Expenses";
import ExpandLess from "../../media/icons/ExpandLess";
import Home from "../../media/icons/Home";
import Companies from "../../media/icons/Companies";
import { NavLink } from "react-router-dom";
import { uniqueId } from "../../utils/functions";

class SidebarItemCollapsible extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isCollapsed: false };
        this.toggleCollapsible = this.toggleCollapsible.bind(this);
    }

    toggleCollapsible(e) {
        e.stopPropagation();
        this.setState({ isCollapsed: !this.state.isCollapsed });
    }

    render() {
        const isCollapsedClass = this.state.isCollapsed ? "_collapsed" : "";
        const {
            toggleCollapsible,
            state: { isCollapsed },
            props: { title, mainRedirect, children, icon }
        } = this;

        let itemIcon = null;
        if (icon === "expenses") itemIcon = <Expenses />;
        if (icon === "people") itemIcon = <People />;
        if (icon === "home") itemIcon = <Home />;
        if (icon === "companies") itemIcon = <Companies />;
        const expandClass = isCollapsed ? "expand-icon_less" : "expand-icon_more";
        const prefix = "sidebar-item-collapsible";

        return (
            <div className={`${prefix}-container`}>
                <div className={`${prefix}-item-title-container`}>
                    <div className={`${prefix}-link-item sidebar-item`}>
                        <NavLink
                            to={mainRedirect}
                            className="sidebar-item-icon"
                            activeClassName="active"
                        >
                            {itemIcon}
                        </NavLink>

                        <NavLink
                            to={mainRedirect}
                            className="sidebar-collapsible-title"
                            activeClassName="active"
                        >
                            {title}
                        </NavLink>
                    </div>
                    <div
                        className="sidebar-expand flex-row-align-end"
                        onClick={(e) => toggleCollapsible(e)}
                    >
                        <span className={`sidebar-icon-expand ${expandClass}`}>
                            <ExpandLess />
                        </span>
                    </div>
                </div>

                <div className={`${prefix}`}>
                    <div className={`${prefix}-children${isCollapsedClass}`}>
                        {isCollapsed &&
                            children.map((elem) => (
                                <NavLink
                                    activeClassName="sidebar-item-link_selected"
                                    className="sidebar-item-link sidebar-collapsible-item-link"
                                    key={uniqueId()}
                                    exact={elem.exact}
                                    to={elem.redirectTo}
                                    //     onClick={(e) => toggleSidebar(e)}
                                >
                                    {elem.text}
                                </NavLink>
                            ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default SidebarItemCollapsible;
