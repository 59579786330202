import React from "react";
import Folder from "../../media/icons/Folder";
import SidebarItemCollapsible from "./SidebarItemCollapsible";
import Home from "../../media/icons/Home";
import { NavLink } from "react-router-dom";
import { routes } from "../../routes";

const {
    requestsMain,
    workersMain,
    // registerWorker,
    // payrollInfo,
    // recentWorkerPayments,
    //allWorkers,
    // manualPayment,
    recentPayments,
    pendingPayments,
    // allPayments,
    /*     newPayroll, */
    payrollMain,
    // payrollConfig,
    payments,
    addCompany,
    allCompanies,
    registerWorker
    // paymentsMain
} = routes;

const ItemsContainer = ({ toggleSidebar, path }) => {
    return (
        <div className="flex-row-align-space-start sidebar-items-container">
            <NavLink
                className="sidebar-item sidebar-item-link sidebar-item_single"
                activeClassName="sidebar-item_selected"
                to={`${path}`}
                exact={true}
            >
                <div className="sidebar-item-icon">
                    <Home />
                </div>
                <span>Inicio</span>
            </NavLink>

            <NavLink
                className="sidebar-item sidebar-item-link sidebar-item_single"
                activeClassName="sidebar-item_selected"
                to={`${path}/${requestsMain}`}
                exact={true}
            >
                <div className="sidebar-item-icon">
                    <Folder />
                </div>
                <span>Solicitudes</span>
            </NavLink>

            <SidebarItemCollapsible
                title="Pagos"
                path={path}
                mainRedirect={`${path}/${payments}`}
                children={[
                    {
                        text: "Nóminas",
                        redirectTo: `${path}/${payrollMain}`
                    },
                    {
                        text: "Pagos en proceso",
                        redirectTo: `${path}/${pendingPayments}`
                    },
                    /*     {
                        text: "Dashboard",
                        redirectTo: `${path}/${paymentsMain}`
                    },
                  {
                        text: "Recibo",
                        redirectTo: `${path}/${receipt}`
                    }, */
                    /*   {
                        text: "Nueva nómina",
                        redirectTo: `${path}/${newPayroll}`
                    }, */

                    /*    {
                        text: "Todos los pagos",
                        redirectTo: `${path}/${allPayments}`
                    },
                    */
                    {
                        text: "Historial de pagos",
                        redirectTo: `${path}/${recentPayments}`,
                        exact: false
                    }
                    //     {
                    //       text: "Pago manual",
                    //     redirectTo: `${path}/${manualPayment}`
                    // }

                    //                    {
                    //                      text: "Configuración de nómina",
                    //                    redirectTo: `${path}/${payrollConfig}`
                    //              }
                ]}
                icon="expenses"
                //    toggleSidebar={toggleSidebar}
            />
            <SidebarItemCollapsible
                title="Trabajadores"
                path={path}
                mainRedirect={`${path}/${workersMain}`}
                children={[
                    {
                        text: "Registro de trabajador ",
                        redirectTo: `${path}/${registerWorker}`
                    }
                    /*      {
                        text: "Lista de trabajadores ",
                        redirectTo: `${path}/${allWorkers}`
                    } */
                    /*   {
                        text: "Perfil de trabajador",
                        redirectTo: `${path}/${workerProfile}`
                    }, 
                    {
                        text: "Editar trabajador ",
                        redirectTo: `${path}/${payrollInfo}`
                    }*/
                ]}
                //  toggleSidebar={toggleSidebar}
                icon="people"
            />
            <SidebarItemCollapsible
                title="Empresas"
                path={path}
                mainRedirect={`${path}/${allCompanies}`}
                children={[
                    {
                        text: "Añadir empresa ",
                        redirectTo: `${path}/${addCompany}`
                    }
                    /*  {
                        text: "Empresas",
                        redirectTo: `${path}/${allCompanies}`
                    } */
                ]}
                //  toggleSidebar={toggleSidebar}
                icon="companies"
            />
        </div>
    );
};

export default ItemsContainer;
