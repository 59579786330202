import React from "react";
//import Select from './Select';
import Select from "react-select";
import "../../../styles/CSS/global-components/select.css";
import PropTypes from "prop-types";

class LabeledSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            update: true
        };
        this.setPersistentValue = this.setPersistentValue.bind(this);
        this.value = this.setPersistentValue();
        this.handleChange = this.handleChange.bind(this);
        this.isValid = this.props.value || this.value || !this.props.forceInvalid;

        //          false;
    }

    componentDidUpdate() {
        const { didUpdateCallback, didUpdateKey } = this.props;
        if (didUpdateCallback) {
            didUpdateCallback(this[didUpdateKey]);
        }
    }

    setPersistentValue() {
        const value = this.props.selectData.find(
            (elem) => elem.value === this.props.value
        );
        this.isValid = true;
        if (value) return value;
        return null;
    }

    handleChange(selectedOption) {
        const { mainParentKey, parentKey, name, index } = this.props;
        const inputData = {
            mainParentKey,
            parentKey,
            name,
            index
        };
        const { value, label, ...otherSelectData } = selectedOption;
        this.value = selectedOption.value;
        const res = { ...inputData, ...otherSelectData, value };
        this.props.callback(res);
        this.isValid = true;
        this.setState({ update: true });
    }

    render() {
        const {
            handleChange,
            value,
            props: {
                mainParentKey,
                parentKey,
                name,
                index,
                labelClassName,
                direction,
                label,
                selectData,
                placeholder,
                containerClassName,
                selectClassName,
                isDisabled,
                layoutSpacingClassName
            },
            isValid
        } = this;

        const inputData = {
            mainParentKey,
            parentKey,
            name,
            index
        };
        const labelElement = label && <label className={labelClassName}> {label}</label>;
        const containerClassNameFull = `labeled-select-container labeled-select-container_${direction} ${layoutSpacingClassName}  ${containerClassName} ${
            isDisabled ? "select_disabled" : ""
        }
        ${isValid ? "" : "input-container-invalid"}
        `;

        return (
            <div className={containerClassNameFull}>
                {labelElement}
                <Select
                    className={`react-select-container ${selectClassName}`}
                    inputData={inputData}
                    isDisabled={isDisabled}
                    options={selectData}
                    onChange={(selectedOption) => handleChange(selectedOption)}
                    name={name}
                    placeholder={placeholder}
                    classNamePrefix="react-select"
                    defaultValue={value}
                    _defaultValue={value}
                />
            </div>
        );
    }
}

LabeledSelect.defaultProps = {
    value: false,
    label: "",
    index: false,
    containerClassName: "",
    labelClassName: "",
    placeholder: "",
    direction: "col",
    checkboxContainerClassName: "",
    callback: () => console.log("you forgot to pass the callback"),
    isArray: false,
    parentKey: false,
    layoutSpacingClassName: "data-input-container",
    selectClassName: ""
};

LabeledSelect.propTypes = {
    direction: PropTypes.string,
    containerClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    callback: PropTypes.func,
    title: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string
};

export default LabeledSelect;
