import React from "react";
import LabeledInput from "../inputs/LabeledInput";
import LabeledSelect from "../inputs/select/LabeledSelect";
import { Button } from "../buttons/Buttons";
import Ajax from "../../utils/Ajax";
import "../../styles/CSS/global-components/add-payroll-modal.css";
import LoaderSmall from "../../global-components/loader/LoaderSmall";
import { setDinamicData } from "../../redux/actions/registerWorkerDinamicSelectsActions";
import { connect } from "react-redux";
import { SAVED_PAYROLL_SUCCESSS, FAILED_REQUEST_WARNING } from "../../text-constants";
import { paymentPeriodsSelectData } from "../../utils/constants";

function mapStateToProps(state) {
    return {
        allSelectsData: state.registerWorkerDinamicSelectsReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDinamicData: (payload) => dispatch(setDinamicData(payload))
    };
};
/**
 * Displays a modal
 *
 */
class AddPayrollModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            update: true,
            updatePayrollInputs: true
        };
        this.newPayrollData = {
            name: "",
            paymentPeriodType: "",
            staffType: 0
        };

        this.currentRequest = null;
        this.addNewPayroll = this.addNewPayroll.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    componentWillUnmount() {
        if (this.currentRequest) this.currentRequest.abort();
    }

    addNewPayroll() {
        const { popNotification, closeModal, setDinamicData } = this.props;
        const isDataEmpty = Object.values(this.newPayrollData)
            .map((v) => String(v).trim())
            .includes("");
        if (isDataEmpty) {
            return;
        }
        const newPayrollDataSnakeCase = {
            name: this.newPayrollData.name,
            payment_period_type: this.newPayrollData.paymentPeriodType,
            staff_type: this.newPayrollData.staffType
        };
        this.setState({ isLoading: true });
        this.currentRequest = new Ajax("payrolls", {
            body: newPayrollDataSnakeCase,
            useBaseUrl: true,
            method: "POST"
        });
        this.currentRequest
            .result()
            .then((res) => {
                popNotification(SAVED_PAYROLL_SUCCESSS);
                setDinamicData({
                    payrolls: [
                        {
                            value: res.id,
                            label: res.name
                        },
                        ...this.props.allSelectsData.payrolls
                    ]
                });
                closeModal();

                this.newPayrollData = {
                    name: "",
                    paymentPeriodType: "",
                    staffType: 0
                };
                //   this.setState({ update: true, updatePayrollInputs: uniqueId() });
            })
            .catch((err) => {
                closeModal();
                popNotification(FAILED_REQUEST_WARNING);
                //  this.setState({ isLoading: false });
            })
            .finally((v) => (this.currentRequest = null));
    }

    onChange({ name, value }) {
        this.newPayrollData = {
            ...this.newPayrollData,
            [name]: value
        };
    }
    render() {
        const {
            onChange,
            addNewPayroll,
            state: { isLoading }
        } = this;
        if (isLoading) {
            return <LoaderSmall />;
        }
        return (
            <div
                className="add-payroll-inputs-super"
                onClick={(e) => e.stopPropagation()}
            >
                <h2 className="modal-title">Agregar Nómina</h2>

                <div className="add-payroll-modal-inputs-container">
                    <LabeledInput
                        name="name"
                        label="Nombre"
                        callback={onChange}
                        validationType="str<128"
                        direction="col"
                    />
                    <LabeledSelect
                        name="paymentPeriodType"
                        label="Periodo de pago"
                        callback={onChange}
                        selectData={paymentPeriodsSelectData}
                        direction="col"
                    />
                    {/*      <LabeledInput
                        name="staffType"
                        label="Tipo de personal"
                        callback={onChange}
                        validationType="str<128"
                        direction="col"
                    /> */}
                </div>
                <div className="add-payroll-modal-btn-container grid-center">
                    <Button
                        text="Guardar"
                        type="success"
                        callback={addNewPayroll}
                        className="add-payroll-modal-btn"
                    />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPayrollModal);
