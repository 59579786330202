import * as React from "react";

function SvgSpinner(props) {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="Spinner_svg__lds-rolling"
            style={{
                background: "0 0"
            }}
            {...props}
        >
            <circle
                cx={50}
                cy={50}
                fill="none"
                stroke="#333333"
                strokeWidth={10}
                r={35}
                strokeDasharray="164.93361431346415 56.97787143782138"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1s"
                    begin="0s"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    );
}

export default SvgSpinner;
