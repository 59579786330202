import * as React from "react";

function SvgCompanies(props) {
    return (
        <svg width={20} height={20} fill="none" {...props}>
            <path fill="url(#companies_svg__pattern0)" d="M0 0h20v20H0z" />
            <defs>
                <pattern
                    id="companies_svg__pattern0"
                    patternContentUnits="objectBoundingBox"
                    width={1}
                    height={1}
                >
                    <use xlinkHref="#companies_svg__image0" transform="scale(.01563)" />
                </pattern>
                <image
                    id="companies_svg__image0"
                    width={64}
                    height={64}
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQflAQkIDSxDUAW9AAAD6klEQVRo3u2ZQWgcVRjHf99stibahlSqh7YWMWQ2IdqYXQrFQtEggiAoihF6iBXjwZslCFWSZjpbPVgvijdBJRUKGzAq2Iti8daDG2pikt0hqcZ4CTbYJtGozcznIdnsJrtpJruzWZT93773vjfvt9+8/c97MwJgx+RxVX72HBzrT3ZUYhnygby6FiszpNWRlDiuwy+WV3aAeI++u2nvEo44niMp0ruc0zfLAmA7NLGoJ0KznqnNhqkmJnUFc2dJiaOOpEi7P1nLQQEsE+LLM09nm1TeOuSahulFJILJIYwC425xjRRpdUJpI/3mb8UD1BAC+XsdkzLNNF+vRFYtZshUU0xtxmTvalKYCBEQPDziP3rv9F8oFmALWX8xwkgmevseL+JGxMQkQiO7Vmv2oAzE7+57r7hboCCDfZ3bH2rVcH/I1GZaOUEti9591o0yVOA2AMtMMsklsCc4z25p5/L2r2Jsf0i+dBqAfcWMDQSgFFUB8hbh2eeM5zNY6mqifyjTE2/UXrkrE3lOS3+nWwYA+Ujrc6InWQPgDU5qtoeJ7zJmVYryboEMkv1driSyPd5XLOYkpsJXS5++QAX6uukunNo/xJ4gptyiAjutKkDFAao+8D/xgX+YYq88ZUcl6Q5b10oCKEbh8HIj0ESXYmDPM0qSJElvYutzRSAAt0Ryw3qOcQzAuGEPa1KGJdk7KVp4bCAAK/LeN14hRowY7dwJQAMd0gFKfMEeKVyVAAHEPTPGGANg1RgtxDQqUR5m5Y+7Z60q82evkiSpSStVECAIH7CWGWWUTyARGo8YUWIalfbVJVwvxzkOgj0mr/V9U2Yf6HQZZ5xPwTJCTV5UosRopwGAVr10riMfYFBPEloNNviAvMDutXCbPmB5pElzEVTOPeBF5QleJuxZFdgPiDLFFIN2I49xpIIPI7kO1Ff8aVgFKON+wF9+OX3AV34Z9wP+8svoA/7yK74IqwAVB9h0Q2Lda3TTKuH1rTpjXOz93t+li/SBFdkP8S37IH8j552Kn/L5RrA4HwCwariw6Tsv0fPWYT/zF+kDAMYR2oDP6rpe/yO3PRFKvcSHhI0X6dkaoAQf0P0Amlg/PXS6zR8DcNBPBfzJuE1rgUPFyjJafwwoB8AOquIAAR5MNqokHwhExftAMCrBB4JRdT/wnwFYADWtHQexDCLATbGHeAb4QZxspy7QAjLHUv5APQgywzoz1iW6gAGpw78iehhI1MhpfZQG2rQt2ydX9GihvUB2Rn0kN5QrCoipR7dZhjmvJ3R5ruNzDnCAO3I6apld+0aaP/si1zc8D2uZ5Xf2r74Z8qd5vvCetX79F7olsTdNv3RaAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAxLTA5VDA4OjEzOjQ0KzAwOjAwEZIR8wAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMS0wOVQwODoxMzo0NCswMDowMGDPqU8AAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC"
                />
            </defs>
        </svg>
    );
}

export default SvgCompanies;
