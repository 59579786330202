import React from "react";
import { connect } from "react-redux";
import "../../styles/CSS/global-components/pop-legal-notifications.css";
import Exclamation from "../../media/icons/Exclamation";
import Remove from "../../media/icons/Remove";
import { resetNotification } from "../../redux/actions/popLegalNotificationsActions";

function mapStateToProps(state) {
    return {
        notificationsText: state.popLegalNotificationsReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetNotification: () => dispatch(resetNotification())
    };
};

class PopLegalNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNotification: false
        };
        this.resetNotification = this.resetNotification.bind(this);

        this.displayNotification = this.displayNotification.bind(this);
    }

    resetNotification() {
        //this.setState({ showNotification: false });
        this.props.resetNotification();
    }
    componentDidUpdate(prevProps) {
        if (
            this.state.showNotification === false &&
            this.props.notificationsText.id !== prevProps.notificationsText.id
        ) {
            this.displayNotification();
        }
    }

    displayNotification() {
        this.setState({ showNotification: true });
    }

    render() {
        const { resetNotification } = this;
        const containerClassName = `pop-legal-notifications-super  ${
            this.props.notificationsText.text.length
                ? "pop-legal-notifications-super_active"
                : ""
        }`;

        return (
            <div className={containerClassName}>
                <div className="pop-legal-notifications-container">
                    <div className="pop-legal-notifications-icon-container grid-center">
                        <Exclamation />
                    </div>
                    <div className="pop-legal-notifications-text-container">
                        <h3 className="pop-legal-notifications-title bold">
                            Advertencia:{" "}
                        </h3>
                        {this.props.notificationsText.text.map((v) => (
                            <h3 className="pop-legal-notifications-subtitle">{v}</h3>
                        ))}
                    </div>

                    <div
                        className="pop-legal-notifications-icon-container grid-center"
                        onClick={resetNotification}
                    >
                        <Remove />
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PopLegalNotifications);
