import React from "react";

import "../../styles/CSS/global-components/buttons.css";

export const Button = (props) => {
    const { className, callback, text, fullWidth, type, elemType } = props;
    const elemClassName = ` btn btn_${type} ${className ? className : ""}  ${
        fullWidth ? "btn_full-width" : ""
    }`;
    return (
        <button onClick={callback} type={elemType} className={elemClassName}>
            {text}
        </button>
    );
};

export const ButtonPrimary = (props) => {
    const { className, callback, text, fullWidth, type } = props;
    const elemClassName = ` btn btn_primary ${className ? className : ""}  ${
        fullWidth ? "btn_full-width" : ""
    }`;
    return (
        <button type={type} onClick={callback} className={elemClassName}>
            {text}
        </button>
    );
};

export const ButtonSecondary = (props) => {
    return <button className="btn btn_secondary">{props.text}</button>;
};

export const ButtonSuccess = (props) => {
    const { isDIsabled, callback, text, fullWidth } = props;
    const elemClassName = `btn btn_success ${fullWidth ? "btn_full-width" : ""} ${
        isDIsabled ? "btn_disabled" : ""
    } `;
    return (
        <button className={elemClassName} onClick={callback}>
            {text}
        </button>
    );
};

export const ButtonDanger = (props) => {
    return <button className="btn btn_danger">{props.text}</button>;
};

export const ButtonDefault = ({ className, text, callback }) => {
    return (
        <button
            onClick={callback}
            className={`btn btn_default ${className ? className : ""}`}
        >
            {text}
        </button>
    );
};

export const ButtonPrimaryOutline = (props) => {
    return <button className="btn btn_primary_outline">{props.text}</button>;
};

ButtonPrimary.defaultProps = {
    type: "button"
};

Button.defaultProps = {
    type: "primary",
    elemType: "button"
};
