import {
    fullDateFormat,
    DMY_dateFormat,
    nighthourStart,
    nighthourEnd,
    MAXIMUM_DAILY_SCHEDULE_START,
    MAXIMUM_DAILY_SCHEDULE_END,
    DAILY_SCHEDULE_START,
    DAILY_SCHEDULE_START_FORMATTED,
    DAILY_SCHEDULE_END_FORMATTED,
    DAILY_SCHEDULE_END,
    MAXIMUM_AMOUNT_OF_DAY_MINUTES_IN_A_NIGHT_SCHEDULE
} from "./timeConstants";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { DAY_SCHEDULE, MIXED_SCHEDULE, TODAY } from "./constants";
import {
    getUniqueValues,
    getValidHoursBySchedule,
    maximumAmountOfNightMinutesBySchedule
} from "./functions";
import {
    INVALID_DAY_SCHEDULE,
    INVALID_DURATION_WARNING,
    INVALID_NIGHT_HOURS
} from "../text-constants";

const moment = extendMoment(Moment);

/**
 * Counts the amount of night hours in a mixed period.
 * Receives the start and end of the periods fullDateFormat
 *  as params
 *
 * @param {string} startTime Start of the period. date in fullDateFormat
 * @param {string} endTime End of the period. date in fullDateFormat
 * @returns {number} the  amount of hous of nighthours of the period.
 */
//fix
export function countPeriodNightHours(startTime, endTime) {
    console.log(startTime, endTime, "xxx");
    const start = moment(startTime, fullDateFormat);
    const end = moment(endTime, fullDateFormat);

    const x1 = `${moment(startTime).format(DMY_dateFormat)} ${nighthourStart}`;
    const x2 = `${moment(startTime)
        .add(1, "day")
        .format(DMY_dateFormat)} ${nighthourEnd}`;

    const x3 = moment(
        `${moment(startTime).format(DMY_dateFormat)} 00:00:00`,
        fullDateFormat
    );
    const x4 = moment(
        `${moment(startTime).format(DMY_dateFormat)} 04:59:00`,
        fullDateFormat
    );

    const periodRange = moment.range(start, end);
    const nightRange = moment.range(x1, x2);
    const morningRange = moment.range(x3, x4);

    const rangeMorningOverlap = periodRange.intersect(morningRange);
    const rangesOverlap = periodRange.intersect(nightRange);

    console.log(rangesOverlap, rangeMorningOverlap);
    let morningOverlap = 0;
    let overlap = 0;
    if (rangeMorningOverlap) morningOverlap = rangeMorningOverlap.diff("minutes");
    if (rangesOverlap) overlap = rangesOverlap.diff("minutes");

    return overlap + morningOverlap;
}
export function countPeriodDayHours(startTime, endTime) {
    const start = moment(startTime, fullDateFormat);
    const end = moment(endTime, fullDateFormat);

    const x1 = `${moment(startTime).format(
        DMY_dateFormat
    )} ${DAILY_SCHEDULE_START_FORMATTED}`;
    const x2 = `${moment(startTime).format(
        DMY_dateFormat
    )} ${DAILY_SCHEDULE_END_FORMATTED}`;

    const periodRange = moment.range(start, end);
    const dayRange = moment.range(x1, x2);
    const rangesOverlap = periodRange.intersect(dayRange);
    const overlap = rangesOverlap ? rangesOverlap.diff("minutes") : 0;
    return overlap;
}

/**
 * Returns if a period is between the valid day hours
 * @param {String} startTime Date in fullDateFormat
 * @param {String} endTime Date in fullDateFormat
 */
export function periodContainsNightHours(startTime, endTime) {
    const reviseTime = (t) =>
        new Date(t).getHours() < DAILY_SCHEDULE_START ||
        new Date(t).getHours() >= DAILY_SCHEDULE_END;
    return reviseTime(startTime) || reviseTime(endTime);
}

/**
 * Checks if a day period is between the valid day hours.
 * A day period can only be between 5am to 6pm
 * Receives the start and end of the periods fullDateFormat
 *  as params
 * @param {String} startTime
 * @param {String} endTime
 */
export function isScheduleInvalid1(startTime, endTime) {
    const startTimeDate = moment(startTime.split(" ")[0], DMY_dateFormat);
    //separates the date from the full format with the hours

    const maximumStartHourBySchedule = moment(
        `${startTimeDate.format(DMY_dateFormat)} ${MAXIMUM_DAILY_SCHEDULE_START}`,
        fullDateFormat
    );
    const maximumFinalHourBySchedule = moment(
        `${startTimeDate
            .add(1, "day")
            .format(DMY_dateFormat)} ${MAXIMUM_DAILY_SCHEDULE_END}`,
        fullDateFormat
    );

    return (
        moment(startTime, fullDateFormat).isBetween(
            maximumStartHourBySchedule,
            maximumFinalHourBySchedule,
            "hours"
        ) ||
        moment(endTime, fullDateFormat).isBetween(
            maximumStartHourBySchedule,
            maximumFinalHourBySchedule,
            "hours"
        )
    );
}

export function checkOverlap(range, index, array) {
    return array.map((v, i) => {
        if (index === i) return false;
        return range.overlaps(v.range, { adjacent: false });
    });
}
/**
 * Returns if an array of periods overlap.
 * Need to be optimized
 * @param {string[]} periods
 * @returns {boolean}
 */
export function doPeriodsOverlap(periods) {
    return periods
        .map((v, index) => {
            const { start_time, end_time } = v;
            const start = moment(start_time, fullDateFormat);
            const end = moment(end_time, fullDateFormat);
            const range = moment.range(start, end);
            return { range, index };
        })
        .map((v, i, array) => checkOverlap(v.range, v.index, array))
        .flat()
        .includes(true);
}

export function separatePeriods(periods) {
    let oldPeriods = [];
    let newPeriods = [];

    periods.forEach((v) => {
        if (v.isOriginal) {
            oldPeriods.push(v);
            return;
        }
        newPeriods.push(v);
    });
    return { oldPeriods, newPeriods };
}

export const startDateIsAfterAvailabilityDate = (availability_date, start_date) => {
    const availabilityMoment = moment(availability_date, "YYYY-MM-DD");
    const startMoment = moment(start_date, "YYYY-MM-DD");
    const startIsAfterToday = startMoment.isAfter(moment());

    return (
        availabilityMoment.isValid() &&
        startMoment.isValid() &&
        startIsAfterToday &&
        startMoment.isAfter(availability_date)
    );
};

export const checkContractConditionIsValid = (contract_condition, startDate, endDate) => {
    if (contract_condition !== 0) return true;
    const endDateMoment = moment(endDate, "YYYY-MM-DD", true);
    const startDateMoment = moment(startDate, "YYYY-MM-DD", true);

    return (
        endDate !== "" &&
        startDateMoment.isValid() &&
        endDateMoment.isValid() &&
        endDateMoment.isAfter(startDateMoment)
    );
};

export const getDuration = (startTime = "00:00", endTime = "00:00", format = "HH:mm") => {
    const start = moment(startTime, format);
    const end = moment(endTime, format);
    if (start.isAfter(end)) end.add(1, "d");

    return moment.duration(end.diff(start)).asHours();
};

export const validateSchedule = (
    { start_time, end_time, rest_hours },
    { shiftType, scheduleType }
) => {
    console.table({ shiftType, scheduleType });
    const validHoursBySchedule = getValidHoursBySchedule(scheduleType, shiftType);
    let today = TODAY.format("YYYY-MM-DD");

    const startFormated = `${today} ${start_time}`;
    const endFormatted = `${today} ${end_time}`;

    const startFormatedM = moment(`${today} ${start_time}`, fullDateFormat);
    const endFormattedM = moment(`${today} ${end_time}`, fullDateFormat);

    if (startFormatedM.isAfter(endFormattedM)) endFormattedM.add(1, "d");

    const duration =
        getDuration(startFormated, endFormatted, fullDateFormat) - rest_hours;
    const schedulesHasInvalidDuration = duration > validHoursBySchedule;

    let nightime = countPeriodNightHours(
        startFormatedM.format(fullDateFormat),
        endFormattedM.format(fullDateFormat)
    );

    const totalDayMinutes = countPeriodDayHours(startFormated, endFormatted);
    const isDayOrMixedSchedule =
        scheduleType === DAY_SCHEDULE || scheduleType === MIXED_SCHEDULE;
    const isValid = isDayOrMixedSchedule
        ? nightime <= maximumAmountOfNightMinutesBySchedule(scheduleType)
        : totalDayMinutes <= 180;

    return isValid && !schedulesHasInvalidDuration;
};

export function validateSchedules(allSchedules, { schedule_type, shift_type }) {
    let errorMessage = "";
    const isInvalid = allSchedules
        .map((v) => {
            const { start_time, end_time, rest_hours } = v;
            let today = TODAY.format("YYYY-MM-DD");
            const validHoursBySchedule = getValidHoursBySchedule(
                schedule_type,
                shift_type
            );

            const startFormated = `${today} ${start_time}`;
            const endFormatted = `${today} ${end_time}`;

            const startFormatedM = moment(`${today} ${start_time}`, fullDateFormat);
            const endFormattedM = moment(`${today} ${end_time}`, fullDateFormat);

            if (startFormatedM.isAfter(endFormattedM)) endFormattedM.add(1, "d");

            const duration =
                getDuration(startFormated, endFormatted, fullDateFormat) - rest_hours;
            const schedulesHasInvalidDuration = duration > validHoursBySchedule;

            if (schedulesHasInvalidDuration) {
                errorMessage = INVALID_DURATION_WARNING;
            }
            if (schedule_type === DAY_SCHEDULE || schedule_type === MIXED_SCHEDULE) {
                let nightime = countPeriodNightHours(
                    startFormatedM.format(fullDateFormat),
                    endFormattedM.format(fullDateFormat)
                );

                if (nightime > maximumAmountOfNightMinutesBySchedule(schedule_type)) {
                    errorMessage = INVALID_NIGHT_HOURS(schedule_type);
                }
                return (
                    maximumAmountOfNightMinutesBySchedule > nightime &&
                    schedulesHasInvalidDuration
                );
            } else {
                const totalDayMinutes = countPeriodDayHours(startFormated, endFormatted);

                const isScheduleInvalid =
                    totalDayMinutes > MAXIMUM_AMOUNT_OF_DAY_MINUTES_IN_A_NIGHT_SCHEDULE;

                if (isScheduleInvalid) errorMessage = INVALID_DAY_SCHEDULE;

                return totalDayMinutes > 180 && !schedulesHasInvalidDuration;
            }
        })
        .includes(true);
    const has2RestDays =
        allSchedules
            .map((v) => v.week_days.filter((a) => a.rest))
            .flat()
            .map((v) => v.day_of_week).length === 2;
    console.log(has2RestDays);
    const hasAllDaysSelected =
        getUniqueValues(
            allSchedules.map((v) => v.week_days.map((a) => a.day_of_week)).flat()
        ).length === 7;

    if (!hasAllDaysSelected) {
        return {
            isInvalid: true,
            errorMessage: "TIENE QUE SELCCIONAR TODOS LOS DIAS"
        };
    }

    if (!has2RestDays) {
        return {
            isInvalid: true,
            errorMessage: "TIENE QUE TENER DOS DIAS DE DESCANSO"
        };
    }
    return {
        isInvalid,
        errorMessage
    };
}
