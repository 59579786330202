import React, { memo } from "react";
import { invalidInputText } from "../../utils/InputValidator";

const Toolbar = memo(({ display, validationType }) => {
    return (
        display && (
            <div className="input-toolbar-super">
                <div className="input-toolbar-container slide-in-fwd-center">
                    <span className="input-toolbar">
                        {invalidInputText[validationType]}
                    </span>
                </div>
            </div>
        )
    );
});

export default Toolbar;
