const requiredInputs = {
    job_request: {
        requested_position: false,
        expected_salary: false,
        availability_date: false,
        expected_package: false
    },
    worker_profile: {
        occupation: false,
        position: false,
        contract_condition: false,
        start_date: false,
        salary: false,
        schedule_type: false,
        payroll_id: false,
        end_date: false
    },
    profile: {
        first_name: false,
        middle_name: false,
        last_name: false,
        second_last_name: false,
        idn: false,
        idn_type: false,
        gender: false,
        birthday: false,
        birth_city: false,
        // travel_available: false,
        nationality: false
    },
    user: {
        email: false,
        address: false,
        city_id: false,
        number: false,
        phone_code_id: false
    }
};

const requiredInputsReducer = (state = requiredInputs, action) => {
    switch (action.type) {
        case "SET_VALIDITY":
            const newProfile = {
                ...state[action.payload.mainParentKey],
                [action.payload.name]: action.payload.state
            };
            return { ...state, [action.payload.mainParentKey]: newProfile };

        case "SET_VALIDITY_OF_LOCAL_VALUES":
            return { ...state, ...action.payload };
        case "RESET_DATA":
            return { ...state, ...requiredInputs };
        default:
            return state;
    }
};

export default requiredInputsReducer;
