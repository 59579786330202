import React, { Fragment } from "react";
import { connect } from "react-redux";
import Spinner from "../loader/LoaderSmall";
import Info from "../../media/icons/Info";
import Warning from "../../media/icons/Error";

import "../../styles/CSS/global-components/pop-notifications.css";

function mapStateToProps(state) {
    return {
        popNotificationsData: state.popNotificationsReducer
    };
}
const icons = {
    info: <Info />,
    warning: <Warning />,
    "": null
};

class PopNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNotification: false
        };
        this.displayNotification = this.displayNotification.bind(this);
    }

    /*   componentDidUpdate(prevProps) {
        if (prevProps.popNotificationsText !== this.props.popNotificationsText) {
            this.displayNotification();
        }
    } */
    componentDidUpdate(prevProps) {
        if (
            this.state.showNotification === false &&
            this.props.popNotificationsData.id !== prevProps.popNotificationsData.id
        ) {
            this.displayNotification();
        }
    }

    displayNotification() {
        this.setState({ showNotification: true });
        setTimeout(() => this.setState({ showNotification: false }), 3000);
    }

    render() {
        const {
            state: { showNotification },
            props: {
                popNotificationsData: { elementType, text, notificationType }
            }
        } = this;

        const containerClassName = `pop-notifications-container ${
            elementType === "text" ? "pop-notifications-container_text" : ""
        } ${showNotification ? "pop-notifications-container_active" : ""} ${
            notificationType ? "pop_notifications_" + notificationType : ""
        }`;
        return (
            <div className="pop-notifications-container-super flex-row-align-center">
                <div className={containerClassName}>
                    {elementType === "spinner" ? (
                        <Spinner />
                    ) : (
                        <Fragment>
                            <div className="pop-notifications-icon">
                                {icons[notificationType]}
                            </div>
                            <h6
                                className={`pop-notifications-title pop-notifications-title_${notificationType} `}
                            >
                                {text}
                            </h6>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps)(PopNotifications);
