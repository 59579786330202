import * as React from "react";

function SvgFolder(props) {
    return (
        <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
        </svg>
    );
}

export default SvgFolder;
