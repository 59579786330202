import React from "react";

const ImageContainer = (props) => {
    const { imgSrc, altText, imgClassName, containerClassName, callback } = props;
    return (
        <div onClick={callback ? callback : null} className={`${containerClassName}`}>
            <img src={imgSrc} alt={altText} className={imgClassName} />
        </div>
    );
};

export default ImageContainer;

ImageContainer.defaultProps = {
    containerClassName: "img-container",
    altText: "",
    imgSrc: "".isRequired,
    imgClassName: "img-full-size",
    callback: false
};
