import PercentageDown from "../media/icons/PercentageDown";
import PercentageNeutral from "../media/icons/PercentageNeutral";
import PercentageUp from "../media/icons/PercentageUp";
import { formatDate } from "./functions";
import {
    workerStatus,
    bankAccountType,
    //requestType,
    personalRelationship,
    statusClass,
    contractCondition,
    bloodType,
    gender,
    scheduleType,
    paymentPeriodType,
    civilStatus,
    languageId,
    languageProficiency,
    dominantHand,
    clothing,
    scheduleTypeM,
    shiftType,
    requestStatus,
    payrollItemName
} from "./constants";
import moment from "moment";

const getTransformedDataStatic = {
    boolean: {
        true: "Sí",
        false: "No",
        1: "Sí",
        0: "No"
    },
    workerStatus,
    bankAccountType,
    //requestType,
    personalRelationship,
    statusClass,
    contractCondition,
    bloodType,
    gender,
    orh: {
        false: "Negativo",
        true: "Positivo"
    },
    scheduleType,
    scheduleTypeM,
    clothing,
    shiftType,
    requestStatus,
    paymentStatus: {
        0: "Pendiente",
        1: "A revisar",
        2: "Aprobado",
        3: "Pagado",
        4: "Completado",
        null: "No revisado aun"
    },
    requestType: {
        1: "Nómina",
        2: "Vacaciones",
        3: "Utilidades",
        4: "Adicional"
    },
    paymentPeriodType,
    civilStatus,
    languageId,
    languageProficiency,
    dominantHand,
    payrollItemName
};

window.getTransformedDataStatic = getTransformedDataStatic;
const getTransformedDataDinamic = {
    idn: (v) => v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
    height: (v) => `${v} cm`,
    weight: (v) => `${v}KG`,
    phoneNumber: (v) => v,
    formatNumber: (v) => `${v.toLocaleString(undefined, { minimumFractionDigits: 2 })}`,
    localCurrency: (v) =>
        ` Bs ${v.toLocaleString(undefined, { minimumFractionDigits: 2 })}`,

    // localCurrencyPreFormatted: (v) => `Bs ${v}`,
    localCurrencyPreFormatted: (v) =>
        ` Bs ${Number(Number(v).toFixed(2)).toLocaleString(undefined, {
            minimumFractionDigits: 2
        })}`, //theres probably a better way
    salary: (v) => v.toLocaleString(),
    postalZone: (v) => `Zona Postal ${v}`,
    dateYMDToDMY: (v) => formatDate(v, "DD-MM-YYYY"),
    dateYMDToDM: (v) => formatDate(v, "DD-MM"),
    bankAccountClean: (v) => v.replace(/-/g, ""),
    bankAccount: (v) =>
        v
            .replace(/-/g, "")
            .split("")
            .map((v, i) => `${i % 4 === 0 && i !== 0 ? "-" : ""}${v}`)
            .join(""),
    percentageIcon: (v) => {
        if (v === 0) return PercentageNeutral;
        if (v < 0) return PercentageDown;
        return PercentageUp;
    },
    percentageIconClassName: (v) => {
        const className = "percentage-icon";
        if (v === 0) return `${className}_neutral`;
        if (v < 0) return `${className}_down `;
        return `${className}_up `;
    },
    backDate: (v) => moment(v).format("YYYY-DD-MM"),
    languageId: (v) => "fix languageId"
};
/**
 *  The function will search the value through two objects.  getTransformedDataStatic where possible values are already part of the object and getTransformedDataDinamic where every key is a function returning a new value according to its params.
 *
 *
 * @param {string}  value The value you need to transform
 * @param {number | string} type The type of the value you will transform.
 * @return {string}
 *
 * If it can´t find the type it will throw an error
 */

function getTransformedValue(value, type) {
    if (!getTransformedDataStatic[type]) {
        return getTransformedDataDinamic[type](value);
    }
    return getTransformedDataStatic[type][value];
}
export default getTransformedValue;
