import { getConstantsKeys } from "./functions";
import { SCHEDULES } from "./constants";

export const maxPeriodsAmount = 5;
export const fullDateFormat = "YYYY-MM-DD HH:mm:ss";
export const DMY_dateFormat = "YYYY-MM-DD";
export const HHMM_dateFormat = "HH:mm";

export const MAXIMUM_DAILY_SCHEDULE_START = "19:01:00";
export const MAXIMUM_DAILY_SCHEDULE_END = "04:59:00";

export const sNightHours = ["19", "20", "21", "22", "23"].map((v) => `${v}:00:00`);

export const eNightHours = ["00", "01", "02", "03", "04"].map((v) => `${v}:00:00`);
export const MAXIMUM_AMOUNT_OF_NIGHT_HOURS_IN_A_DAY_SCHEDULE = 0;
export const MAXIMUM_AMOUNT_OF_NIGHT_HOURS_IN_A_MIXED_SCHEDULE = 4;

export const MAXIMUM_AMOUNT_OF_DAY_MINUTES_IN_A_NIGHT_SCHEDULE = 180;
export const nighthourStart = "19:00:00";
export const nighthourEnd = "04:59:00";

export const dayScheduleStart = "05:00:00";
export const dayScheduleEnd = "19:00:00";

export const nightHours = [
    "19",
    "20",
    "21",
    "22",
    "23",

    "00",
    "01",
    "02",
    "03",
    "04"
].map((v) => `${v}:00:00`);

const {
    DAY_SCHEDULE: daySchedule,
    NIGHT_SCHEDULE: nightSchedule,
    MIXED_SCHEDULE: mixedSchedule
} = getConstantsKeys(SCHEDULES);

/**
 *
 */
export const MIXED_SCHEDULE = mixedSchedule;
export const NIGHT_SCHEDULE = nightSchedule;
export const DAY_SCHEDULE = daySchedule;

export const DAILY_SCHEDULE_START = 5;
export const DAILY_SCHEDULE_END = 19;

export const DAILY_SCHEDULE_START_FORMATTED = "05:00:00";
export const DAILY_SCHEDULE_END_FORMATTED = "19:00:00";

export const restHoursAmount = 2;
