import React from "react";
import Logout from "../../media/icons/Logout";
import { logout } from "../../redux/actions/userSessionActions";
import { connect } from "react-redux";
import { userSession } from "../../endpoints";
import Ajax from "../../utils/Ajax";
import { showSpinner, closeModal } from "../../redux/actions/modalActions";
import { popNotification } from "../../redux/actions/popNotificationsActions";
import { FAILED_LOGOUT_WARNING } from "../../text-constants";

const { LOGOUT: LOGOUT_ENDPOINT } = userSession;
const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
        showSpinner: () => dispatch(showSpinner()),
        closeModal: () => dispatch(closeModal()),
        popNotification: (payload, notificationType) =>
            dispatch(popNotification(payload, notificationType))
    };
};

/**
 * ON L
 * @param {} param0
 */
const SidebarLogout = ({
    logout,
    showSpinner,
    closeModal,
    popNotification,
    deleteCachedData
}) => {
    function logoutUser() {
        const requestHeader = {
            useBaseUrl: true
        };
        const xhr = new Ajax(LOGOUT_ENDPOINT, requestHeader);
        showSpinner();
        xhr.result()
            .then((res) => logout())
            .catch((err) => {
                console.log(err.status, "logout err");
                if (err.status === 401 || err.status === 400) {
                    localStorage.clear();
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                    return;
                }

                popNotification(FAILED_LOGOUT_WARNING, "warning");
            })
            .finally(() => closeModal());
    }

    return (
        <div className="sidebar-log-out-container">
            <div className="sidebar-log-out" onClick={logoutUser}>
                <div className="sidebar-logout-icon">
                    <Logout />
                </div>
                <h5 className="sidebar-log-out-link"> Cerrar sesión</h5>
            </div>
        </div>
    );
};

export default connect(null, mapDispatchToProps)(SidebarLogout);
