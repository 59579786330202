import React from "react";
import "../../styles/CSS/global-components/inputs.css";
import InputRegular from "./InputRegular";
import PropTypes from "prop-types";
//import { memo } from "react";

const LabeledInput = (props) => {
    const {
        direction,
        layoutSpacingClassName,
        containerClassName,
        label,
        labelClassName,
        mainParentKey,
        parentKey,
        name,
        index,
        isArray,
        additionalInputData,
        ...inputProps
    } = props;

    const inputData = {
        mainParentKey,
        parentKey,
        name,
        index,
        isArray,
        ...additionalInputData
    };

    const elemContainerClassName = `labeled-input-container_${direction} ${layoutSpacingClassName} ${containerClassName}`;
    const labelElement = label && <label className={labelClassName}> {label}</label>;

    return (
        <div className={elemContainerClassName}>
            {labelElement}
            <InputRegular {...inputProps} inputData={inputData} />
        </div>
    );
};

LabeledInput.defaultProps = {
    index: false,
    value: false,
    min: "",
    validationType: "".isRequired,
    containerClassName: "",
    labelClassName: "",
    inputClassName: "",
    parentKey: false,
    direction: "col",
    type: "text",
    isRequired: false,
    sendRawValue: false,
    toolBar: false,
    inputTransform: false,
    label: "",
    additionalInputData: {},
    autocomplete: "off",
    isArray: false,
    mainParentKey: false.isRequired,
    layoutSpacingClassName: "data-input-container",
    callback: () => console.log("you forgot to pass the callback"),
    didUpdateCallback: false,
    didUpdateKey: false,
    isDisabled: false,
    inputElementProps: {}
};

//parent key can be  be a falsy value, an object, or a string. :(

LabeledInput.propTypes = {
    direction: PropTypes.string,
    validationType: PropTypes.string,
    containerClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    callback: PropTypes.func,
    label: PropTypes.string,
    sendRawValue: PropTypes.bool,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    index: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    parentKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
    maxLength: PropTypes.string,
    inputElementProps: PropTypes.object
};

export default LabeledInput;
