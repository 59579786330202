import moment from "moment";
import { isValidNumber } from "./functions";
import {
    MAX_HEIGHT,
    MAX_WEIGHT,
    /*    ACCEPTED_TEXT_FILES_FORMATS,
    ACCEPTED_IMAGE_FILES_FORMATS,
    INVALID_FILE_MESSAGE, */
    ACCEPTED_TEXT_FILES_MESSAGE,
    ACCEPTED_IMAGE_FILES_MESSAGE,
    acceptedTextFiles,
    acceptedImageFiles,
    ACCEPTED_TEXT_AND_IMAGE_FILES_MESSAGE,
    MAXIMUM_FILE_SIZE,
    currentYearInt,
    MIN_WORKER_AGE,
    aceptedTextFilesFormatsKeys,
    aceptedImageFormatsKeys
} from "./constants";
import { DMY_dateFormat } from "./timeConstants";
moment.locale("es");

const onlyNumbersFrom0To12Chars = /^.[0-9]{0,11}$/;
const onlyNumbersWith16Chars = /^.[0-9]{15}$/;

export const isEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const invalidInputText = {
    /**
     * This is the toolbar text on invalid input value
     */
    dateDMY: "Ingrese fecha en formato DD/MM/AAAA",
    idn: "Ingrese una cédula válida",
    rif: "Ingrese un RIF válido",
    year: "Ingrese un año válido",
    smallInt: "Ingrese un número válido",
    bigInt: "Ingrese un número válido",
    weight: "Ingrese un número válido",
    height: "Ingrese una altura válida",
    "str<64": "Máximo 64 carácteres",
    "str<128": "Máximo 128 carácteres",
    "str<20": "Máximo 20 carácteres",
    "str<100": "Máximo 100 carácteres",
    "str<150": "Máximo 100 carácteres",
    "str<255": "Máximo 255 carácteres",
    "str<500": "Máximo 500 carácteres",
    "str<512": "Máximo 512 cáracteres",
    email: "Ingrese un email válido",
    password: "Ingrese una contraseña mayor a 6 carácteres",
    phoneNumber: "Ingrese un número válido",
    imageFile: ACCEPTED_IMAGE_FILES_MESSAGE,
    textFile: ACCEPTED_TEXT_FILES_MESSAGE,
    imageOrTextFile: ACCEPTED_TEXT_AND_IMAGE_FILES_MESSAGE,
    dateDMYAfterToday: "Ingrese una fecha futura",
    minAgeDate: "La edad mínima de un trabajador es 15",
    bankAccountNumber: "Ingrese un número de cuenta con 16 carácteres",
    positiveInt: "Ingrese un número válido",
    equalOrMoreThanZeroInt: "Ingrese un número válido",
    zipCode: "Ingrese un codigo postal válido",
    startDate: "Ingrese una fecha futura",
    dateDMYPast: "Ingrese una fecha válda",
    false: "YOU FORGOT TO PASS THE VALIDATION TYPE FIX"
};

export const inputValidatorObj = {
    //general
    idn: (v) => v.length >= 3 && v.length < 9 && !isNaN(+v),
    password: (v) => v.length > 6,
    phoneNumber: (v) => isValidNumber(v) && v.length === 7,
    bigInt: (v) => onlyNumbersFrom0To12Chars.test(v),
    email: (v) => isEmailRegex.test(v) && v.length < 255,
    height: (v) => typeof +v === "number" && +v > 0 && +v < MAX_HEIGHT,
    weight: (v) => typeof +v === "number" && +v > 0 && +v < MAX_WEIGHT,
    rif: (v) => v.length >= 3 && v.length <= 11 && v[0] === "J" && !isNaN(+v.slice(1)),
    smallInt: (v) => typeof +v === "number" && +v > 0 && +v < 999,
    dateDMYPast: (v) =>
        moment(v, DMY_dateFormat, true).isValid() &&
        moment(v, DMY_dateFormat, true).isBefore(),
    dateDMYAfterToday: (v) =>
        moment(v, DMY_dateFormat, true).isValid() &&
        moment(v, DMY_dateFormat, true).isAfter(),
    startDate: (v) => {
        return (
            moment(v, DMY_dateFormat, true).isValid() &&
            moment(v, DMY_dateFormat, true).isAfter()
        );
    },
    equalOrMoreThanZeroInt: (v) => isValidNumber(v) && +v >= 0,
    dateDMY: (v) => moment(v, DMY_dateFormat, true).isValid(),
    minAgeDate: (v) => {
        const dateMoment = moment(v, DMY_dateFormat, true);
        const minYear = currentYearInt - MIN_WORKER_AGE;
        const minDate = moment(`${minYear}-01-01`, DMY_dateFormat, true);
        return dateMoment.isValid() && dateMoment.isBefore(minDate);
    },
    dateSafari: (v) => moment(v, "DD/MM/YYYY", true).isValid(),
    bankAccountNumber: (v) => onlyNumbersWith16Chars.test(v.replace(/-/g, "")),
    year: (v) => v < currentYearInt && v > 1930,
    zipCode: (v) => typeof +v === "number" && +v > 0 && v.length === 4,

    //files
    imageFile: (v) => {
        return (
            MAXIMUM_FILE_SIZE > v.size && aceptedImageFormatsKeys[v.type] !== undefined
        );
    },
    imageOrTextFile: (v) =>
        MAXIMUM_FILE_SIZE > v.size &&
        [...acceptedTextFiles, ...acceptedImageFiles].includes(v.type),
    textFile: (v) =>
        MAXIMUM_FILE_SIZE > v.size && aceptedTextFilesFormatsKeys[v] !== undefined,

    //strings
    "str<20": (v) => v.length < 20 && v.length >= 1,
    "str<64": (v) => v.length < 64 && v.length >= 1,
    "str<100": (v) => v.length < 100 && v.length >= 1,
    "str<128": (v) => v.length < 128 && v.length >= 1,
    "str<150": (v) => v.length < 150 && v.length >= 1,
    "str<255": (v) => v.length < 255 && v.length >= 1,
    "str<500": (v) => v.length < 500 && v.length >= 1,
    "str<512": (v) => v.length < 500 && v.length >= 1,
    positiveInt: (v) => typeof +v === "number" && +v > 0 && +v % 1 === 0,

    _noValidation: () => true //don't use this on production :]
};

/**
 *  Will test if the value passes the conditionals inside the type key on the inputValidatorObject
 *
 *
 *
 * @param {string} type
 * @param {string} value
 *  @returns {boolean}
 */
function inputValidator(type, value) {
    return inputValidatorObj[type](value);
}

export default inputValidator;
