import React from "react";
import { Button } from "../buttons/Buttons";
import { Redirect } from "react-router-dom";

class ModalBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = { redirect: false };
        this.goToRoute = this.goToRoute.bind(this);
    }

    goToRoute() {
        this.setState({ redirect: true });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={`${this.props.route}`} />;
        }
        return (
            <div className="modal-item-btn-container" onClick={this.goToRoute}>
                <Button type="success" text={this.props.title} fullWidth={true} />
            </div>
        );
    }
}

export default ModalBtn;
