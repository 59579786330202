import React from "react";
import LoaderSmall from "../../global-components/loader/LoaderSmall";
import RequestError from "../../global-components/layout/request-error";
import { userSession } from "../../endpoints";
import Ajax from "../../utils/Ajax";
import { loginUserSuccess } from "../../redux/actions/userSessionActions";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => {
    return {
        loginUserSuccess: (loginData) => dispatch(loginUserSuccess(loginData))
    };
};

class InittialRequestHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };

        this.loginRequest = this.loginRequest.bind(this);
    }

    loginRequest() {
        const requestHeader = {
            useBaseUrl: true
        };
        const xhr = new Ajax(userSession.LOGIN_FROM_TOKEN, requestHeader);
        this.setState({
            isLoading: true
        });
        xhr.result()
            .then((res) => {
                const token = JSON.parse(localStorage.mainUserData).currentToken;
                this.props.loginUserSuccess({ ...res.data, token });
            })
            .catch((err) => {
                console.log(err, "handler erro h");
                this.setState({
                    hasError: true,
                    isLoading: false
                });
            });
    }
    render() {
        const {
            loginRequest,
            state: { isLoading }
        } = this;

        if (isLoading) {
            return (
                <div className="no-data-container">
                    <div className="grid-center">
                        <LoaderSmall />
                    </div>
                </div>
            );
        }
        return (
            <RequestError
                message="asds"
                containerClassName="grid-center"
                buttonCallback={loginRequest}
                status={999}
                errorData={{
                    status: 999,
                    message: "Error al iniciar sesion"
                }}
            />
        );
    }
}

export default connect(null, mapDispatchToProps)(InittialRequestHandler);
