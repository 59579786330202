import popNotificationsReducer from "./popNotificationsReducer";
import requiredInputsReducer from "./requiredInputsReducer";
import modalReducer from "./modalReducer";
import userSessionReducer from "./userSessionReducer.js";
import sidebarReducer from "./sidebarReducer.js";
import currentRequestReducer from "./currentRequestReducer";
import registerWorkerDinamicSelectsReducer from "./registerWorkerDinamicSelectsReducer";
import registerWorkerFrontDataReducer from "./registerWorkerFrontDataReducer";
import popLegalNotificationsReducer from "./popLegalNotificationsReducer";
import cacheReducer from "./cacheReducer";
import invalidStartDateReducer from "./invalidStartDateReducer";
import { combineReducers } from "redux";

export default combineReducers({
    popNotificationsReducer,
    requiredInputsReducer,
    modalReducer,
    userSessionReducer,
    sidebarReducer,
    currentRequestReducer,
    registerWorkerDinamicSelectsReducer,
    registerWorkerFrontDataReducer,
    popLegalNotificationsReducer,
    cacheReducer,
    invalidStartDateReducer
});
