const userSessionReducer = (state = { showSidebar: false }, action) => {
    switch (action.type) {
        case "SIDEBAR_TOGGLE":
            return {
                ...state,
                showSidebar: !state.showSidebar
            };
        default:
            return state;
    }
};
export default userSessionReducer;
