import { actions as userSessionActions } from "../actions/userSessionActions";
const {
    LOGIN_USER,
    LOADING,
    LOGIN_WITH_BUSINESS,
    LOGOUT,
    PERSISTENT_LOGIN,
    MANTAIN_LOCAL_TOKEN,
    STOP_LOADING,
    CHANGE_COMPANY
} = userSessionActions;

const initialState = {
    isLoading: true,
    isLogged: false,
    hasBusiness: false,
    user: null,
    businesses: {
        profilePicture: null,
        name: ""
    },
    error: null,
    message: null
};

const userSessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            const { userData, current_business } = action;
            return {
                ...state,
                user: userData,
                businesses: current_business,
                isLogged: true
            };
        case LOADING:
            return {
                ...state
            };
        case LOGIN_WITH_BUSINESS:
            return {
                ...state,
                isLoading: false,
                hasBusiness: true,
                businesses: action.payload.newBusinesses
                //or action.payload.token im not sure
            };
        case LOGOUT:
            return {
                ...initialState,
                isLoading: false
            };
        case CHANGE_COMPANY:
            return {
                ...state,
                isLoading: false,
                businesses: action.companyData
            };
        case PERSISTENT_LOGIN:
            return {
                ...state,
                ...action.payload
            };
        case MANTAIN_LOCAL_TOKEN:
            return {
                ...state,
                token: action.payload
            };
        case STOP_LOADING:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};

export default userSessionReducer;
