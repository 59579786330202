export default function getBaseUrl() {
    let baseUrl = "";
    switch (process.env.NODE_ENV) {
        case "production":
            baseUrl = "https://dev.api.oxoftgroup.com/api/";
            break;
        case "test":
            baseUrl = "https://dev.api.oxoftgroup.com/api/";
            break;
        default:
            baseUrl = "http://localhost:80/oxoft-payroll-api/api/";
            // baseUrl = "http://192.168.0.102:8080/oxoft-payroll-api/api/";
            break;
    }
    return baseUrl;
}