import React from "react";
import SchedulePicker from "../../../../global-components/schedule-picker/SchedulePicker";
import { addScheduleModal, closeModal } from "../../../../redux/actions/modalActions";
import { popNotification } from "../../../../redux/actions/popNotificationsActions";
import { deleteKeys } from "../../../../utils/functions";
import WithRequestCache from "../../../../utils/WithRequestCache";
import { connect } from "react-redux";
import "../../../../styles/CSS/register-worker/register-worker.css";

const mapDispatchToProps = (dispatch) => {
    return {
        popNotification: (notificationText, type) =>
            dispatch(popNotification(notificationText, type)),
        addScheduleModal: (callback) => dispatch(addScheduleModal(callback)),
        closeModal: () => dispatch(closeModal())
    };
};

class EditWorkerSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            update: true
        };
        this.setScheduleWeek = this.setScheduleWeek.bind(this);
        this.setSchedule = this.setSchedule.bind(this);
        this.setScheduleRestDays = this.setScheduleRestDays.bind(this);
        this.dinamicSchedules = [];
        this.worker_schedule = {
            schedulesValid: {},
            selectedDinamicSchedules: {},
            restDays: [],
            schedules: []
        };
    }

    setScheduleWeek({ value: index, name, isDinamicSchedule, id, ...data }) {
        const { value, key } = isDinamicSchedule
            ? {
                  value: id,
                  key: "selectedDinamicSchedules"
              }
            : {
                  value: index,
                  index,
                  key: "schedulesValid"
              };
        const { schedulesValid, selectedDinamicSchedules } = this.worker_schedule;

        //keys are converted to string so deleteKeys wont work if you pass an int
        this.worker_schedule.schedulesValid = deleteKeys(schedulesValid, `${name}`);
        this.worker_schedule.selectedDinamicSchedules = deleteKeys(
            selectedDinamicSchedules,
            `${name}`
        );

        this.worker_schedule[key][name] = value;
    }

    setSchedule({ index, rest_hours, start_time, end_time }) {
        this.worker_schedule.schedules.push({
            // ...this.workerData.worker_schedule.schedules[index],
            rest_hours,
            start_time,
            end_time,
            week_days: []
        });

        this.props.closeModal();
        this.setState({ step: 5 });
        console.log(this.worker_schedule.schedules);
    }

    setScheduleRestDays({ day1, day2 }) {
        this.worker_schedule.restDays = [day1, day2];
    }

    render() {
        const {
            worker_schedule,
            //setSchedule,
            props: {
                setScheduleWeek,
                restDays,
                weekValues,
                schedule_data,
                setSchedule,
                setScheduleRestDays,
                setDinamicSchedules,
                schedules
            }
        } = this;

        const schedule = {
            selectedDinamicSchedules: weekValues,
            restDays,
            schedulesValid: {},
            schedules
        };
        console.log(restDays);
        return (
            <div onClick={() => console.log(this.worker_schedule)}>
                <WithRequestCache
                    endpoint="reusable-schedules"
                    scheduleValues={schedule_data}
                    schedule={schedule}
                    setScheduleWeek={setScheduleWeek}
                    sendScheduleData={() => null}
                    setDinamicSchedules={setDinamicSchedules}
                    setSchedule={setSchedule}
                    setScheduleRestDays={setScheduleRestDays}
                    Component={SchedulePicker}
                />
            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(EditWorkerSchedule);
