import React from "react";
import { connect } from "react-redux";
import ModalItemsContainer from "./ModalItemsContainer";
import "../../styles/CSS/global-components/modal.css";
import {
    closeModal,
    showSpinner,
    showInputsModalAction
} from "../../redux/actions/modalActions";
import { CSSTransition } from "react-transition-group";
import { Button } from "../buttons/Buttons";
import { workers } from "../../endpoints";
import Ajax from "../../utils/Ajax";
import { popNotification } from "../../redux/actions/popNotificationsActions";
import getTransformedValue from "../../utils/getTransformedValue";
import {
    FAILED_REQUEST_WARNING,
    SAVED_WORKER_ATTENDANCE_SUCCESS
} from "../../text-constants";
import AddPayrollModal from "./AddPayrollModal";
import LoaderSmall from "../loader/LoaderSmall";
import Close from "../../media/icons/Close";
import HoursInputs from "../schedule-picker/HoursInputs";

const { ATTENDANCE } = workers;
function mapStateToProps(state) {
    return {
        modalInfo: state.modalReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        popNotification: (payload) => dispatch(popNotification(payload)),
        showSpinner: () => dispatch(showSpinner()),
        closeModal: () => dispatch(closeModal())
    };
};
/**
 * Displays a page wide modal according to its props.
 * The possible modals are:
 *
 *      RegisterWorker : Asks the user if wants to keep the saved
 *     worker data or start over
 *      AddPayrollModal: For adding new Payrolls in RegisterWorker
 *      AssistanceModal: For saving the daily assistance data in AllWorkers
 *      ModalItemsContainer: Displays a list of buttons
 *      Spinner: Displays an overlay and spinner across the page
 *
 * @method saveWorkerId onChange to save the id of the selected worker
 *@method sendWorkerId Request handler to send the worker id
 *
 */
class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.saveWorkerId = this.saveWorkerId.bind(this);

        this.sendWorkerId = this.sendWorkerId.bind(this);
        this.workerId = "";
    }

    /* closeModal(e){
        e.stopPropagation()
    } */

    saveWorkerId({ value }) {
        this.workerId = value;
    }

    sendWorkerId(e) {
        //this.props.workerAssistanceId
        const { id } = this.props.modalInfo.items;

        const requestHeader = {
            method: "POST",
            useBaseUrl: true,
            body: {
                worker_profile_id: id
            }
        };

        const xhr = new Ajax(ATTENDANCE, requestHeader);
        this.props.showSpinner();
        xhr.result()
            .then((res) => {
                this.props.closeModal();
                this.props.popNotification(SAVED_WORKER_ATTENDANCE_SUCCESS);
            })
            .catch((err) => {
                this.props.closeModal();
                this.props.popNotification(FAILED_REQUEST_WARNING);
            });
        //  e.stopPropagation();
    }

    render() {
        const {
            sendWorkerId,
            props: {
                modalInfo: { showModal, title, items, elementType },
                closeModal
            }
        } = this;

        if (showModal) {
            if (elementType === "spinner") {
                return (
                    <div
                        className="modal-container-super modal-super grid-center"
                        onClick={closeModal}
                    >
                        <LoaderSmall />
                    </div>
                );
            }

            if (elementType === "registerWorkerLocalData") {
                const resetWorkerData = items;
                return (
                    <div className="modal-container-super modal-super grid-center">
                        <CSSTransition classNames="roll" timeout={100} in={true} appear>
                            <div className="modal-container slide-in-fwd-center">
                                <h2 className="modal-title">{title}</h2>
                                <div className="modal-register-worker-local-data-buttons-container grid-center">
                                    <div className="modal-register-worker-local-data-button">
                                        <Button
                                            className="modal-register-worker-local-data-inner-btn"
                                            type="success"
                                            text="Continuar"
                                            callback={() => resetWorkerData(false)}
                                        />
                                    </div>
                                    <div className="modal-register-worker-local-data-button">
                                        <Button
                                            className="modal-register-worker-local-data-inner-btn"
                                            text="Reiniciar datos"
                                            callback={() => resetWorkerData(true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                );
            }
            if (elementType === "buttons") {
                return (
                    <div
                        className="grid-center modal-container-super modal-super"
                        onClick={closeModal}
                    >
                        <CSSTransition classNames="roll" timeout={100} in={true} appear>
                            <div className="modal-container slide-in-fwd-center">
                                <h2 className="modal-title"> {title}</h2>
                                {items && <ModalItemsContainer items={items} />}
                            </div>
                        </CSSTransition>
                    </div>
                );
            }
            if (elementType === "assistanceInputs") {
                const { idn, fullName } = this.props.modalInfo.items;
                return (
                    <div className="modal-container-super modal-super grid-center">
                        <div
                            className="close-modal-icon close-modal-icon-outside"
                            onClick={closeModal}
                        >
                            <Close />
                        </div>
                        <div
                            className="modal-container add-assistance-modal-container"
                            //  onClick={(e) => e.stopPropagation()}
                        >
                            {/*    <div className="close-modal-icon" onClick={closeModal}>
                                <Close />
                            </div> */}
                            <h2 className="modal-title add-assistance-modal-title bold">
                                Confirmar Asistencia
                            </h2>
                            <div className="add-assistance-modal-text-container">
                                <h4 className="modal-subtitle add-assistance-modal-subtitle">
                                    {fullName} - {getTransformedValue(idn, "idn")}
                                </h4>
                            </div>

                            {/* <div className="modal-items-container">
                                <LabeledInput
                                    label="ID"
                                    direction="row"
                                    name="id"
                                    validationType="str<20"
                                    callback={saveWorkerId}
                                />
                            </div> */}
                            <div className="modal-button-container grid-center">
                                <Button
                                    className="modal-button"
                                    text="Guardar"
                                    callback={(e) => sendWorkerId(e)}
                                />
                            </div>
                        </div>
                    </div>
                );
            }
            if (elementType === showInputsModalAction) {
                return (
                    <div
                        className="grid-center modal-container-super modal-super"
                        onClick={closeModal}
                    >
                        <div className="modal-container">
                            <AddPayrollModal
                                popNotification={this.props.popNotification}
                                closeModal={closeModal}
                            />
                        </div>
                    </div>
                );
            }

            if (elementType === "add-schedule") {
                return (
                    <div className="modal-container-super modal-super grid-center">
                        <CSSTransition classNames="roll" timeout={100} in={true} appear>
                            <div className="modal-container slide-in-fwd-center">
                                <div
                                    className="close-modal-icon close-modal-icon-outside"
                                    onClick={closeModal}
                                >
                                    <Close />
                                </div>

                                <h2 className="modal-title">Nuevo Horario</h2>
                                <HoursInputs {...items} />
                            </div>
                        </CSSTransition>
                    </div>
                );
            }

            return (
                <div className="modal-container-super modal-super" onClick={closeModal}>
                    <div className="modal-container">
                        <h2 className="modal-title">forgot elementType</h2>
                    </div>
                </div>
            );
        }
        return null;
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
