import moment from "moment";
import {
    MAX_HOURS_FULL_SHIFT_DAY_SCHEDULE,
    MAX_HOURS_FULL_SHIFT_NIGHT_OR_MIXED_SCHEDULE,
    PARTIAL_SHIFT,
    MAX_HOURS_PARTIAL_SHIFT,
    APPROVED_PAYMENT,
    TODAY_UNIX
} from "./constants";
import {
    DAY_SCHEDULE,
    NIGHT_SCHEDULE,
    MIXED_SCHEDULE,
    MAXIMUM_AMOUNT_OF_NIGHT_HOURS_IN_A_DAY_SCHEDULE,
    MAXIMUM_AMOUNT_OF_NIGHT_HOURS_IN_A_MIXED_SCHEDULE
} from "./timeConstants";

export function isURL(str) {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return pattern.test(str);
}
/**
 * Sums every value of a key inside an array of objects and returns the total
 * @param { [object]} data
 * @param {string} key
 * @returns number
 */
export function getTotalAmount(data, key = "total-amount") {
    const totalAmount = data.map((elem) => parseFloat(elem[key]));
    return totalAmount.length ? totalAmount.reduce((a, b) => a + b) : 0;
}

export function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}

export function checkJwtValidity(token) {
    const { exp: expires, nbf: notBeforeThan } = parseJwt(token);
    //    const TODAY_UNIX = parseInt(moment().format("X"));
    return expires > TODAY_UNIX && TODAY_UNIX > notBeforeThan;
}

export function uniqueId() {
    return Math.random().toString(36).substring(7);
}
/**
 * Searchs recursively an array  or object to find a value
 * @param {any} value
 * @param {String | boolean} valueToFind
 * @returns {boolean}
 */
export function isEmpty(value, valueToFind = "") {
    if (Array.isArray(value)) {
        return includesTrue(value.map((v) => isEmpty(v)));
    }

    if (typeof value === "object") {
        return includesTrue(Object.values(value).map((v) => isEmpty(v)));
    }

    return value === valueToFind;
}

export function includesTrue(value) {
    return value.some((v) => v === true);
}

export function checkEmptyInputs(formValues = [], objectIndex = 999) {
    return objectIndex > formValues.length || isEmpty(formValues);
}

export function isNotFalse(value) {
    if (value === false || value === undefined || value === null || isNaN(value)) {
        return false;
    }
    return true;
}

/**
 *
 * @param {string} date
 * @param {string} typeToFormat
 * @param {string} originalDateFormat
 */
export function formatDate(
    date,
    typeToFormat = "DD-MM",
    originalDateFormat = "YYYY-MM-DD"
) {
    return moment(date, originalDateFormat).format(typeToFormat);
}
/**
 *
 * @param {String | number} str
 */
export function isValidNumber(str) {
    const n = Number(str);
    return /* typeof str === "string" && */ typeof n === "number" && !Number.isNaN(n);
}

export function abbreviateNumber(num, fixed) {
    if (num === null) return null;
    if (num === 0) return "0";
    fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
    const b = num.toPrecision(2).split("e"), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
        c =
            k < 1
                ? num.toFixed(0 + fixed)
                : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
        d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
        e = d + ["", "K", "M", "B", "T"][k]; // append power
    return e;
}
/**
 *
 * @param {number} i
 * @returns {string} returns the number as ordinal
 */
export function numberToOrdinal(i) {
    const obj = {
        1: "Primer",
        2: "Segundo",
        3: "Tercer",
        4: "Cuarto",
        5: "Quinto",
        6: "Sexto",
        7: "Séptimo",
        8: "Octavo",
        9: "Noveno",
        10: "Décimo"
    };
    return obj[i];
}
/**
 *
 * @param {string} word
 */
export function capitalize(word) {
    const [firstLetter, ...otherLetters] = [...word];
    return firstLetter.toUpperCase() + otherLetters.join("");
}

export function diffHours(dt2, dt1) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
}
/**
 * Transforms an array into an object where ev
 * @param { Object[]} data
 * @param {string} key
 * @returns {object}
 */
export const getMapFromArray = (data, key) =>
    data.reduce((acc, item) => {
        acc[item[key]] = { ...item };
        return acc;
    }, {});

export function getHours(start, end) {
    const [startHour, startMinute] = start.split(":");
    const [finishHour, finishMinute] = end.split(":");
    return { startHour, startMinute, finishHour, finishMinute };
}

export function formatLongStrings(str, maxCharSize = 25) {
    if (str.length <= maxCharSize - 5) return str;
    return [...str].slice(0, maxCharSize).join("") + "...";
}
/**
 *
 * @param {[]} arr An array with constants ordered
 * @returns {Object} An arry with indexed keys of the constants
 */
export function getConstantsKeys(arr) {
    return arr.reduce((acc, item, index) => {
        acc[item] = index;
        return acc;
    }, {});
}

export function getConstantsValues(arr) {
    return arr.reduce((acc, item, index) => {
        acc[index] = item;
        return acc;
    }, {});
}

/**
 * Contains an array where each item is an array with the data a month´s week
 * Including days from the previous or next month if they are
 * part of the week
 *
 * @returns {Array} An array of arrays containing moment objects with their respective day´s data
 *
 */
export function weekDays(month, year) {
    const endDate = moment().date(0).month(month).year(year);

    return Array(endDate.date())
        .fill(0)
        .map((_, i) =>
            moment()
                .date(i + 1)
                .month(month)
                .year(year)
        )
        .map((day) => ({ day, week: day.week() }))
        .filter(({ week }, i, arr) => arr.findIndex((info) => info.week === week) === i)
        .map(({ day, week }) => ({
            week,
            days: Array(7)
                .fill(0)
                .map((_, i) => moment(day).week(week).startOf("week").add(i, "day"))
        }));
}

export function addTotalHours(start, end) {
    const fullDateFormat = `YYYY-MM-DD hh:mm:ss`;
    const newStart = moment(start, fullDateFormat);
    const newEnd = moment(end, fullDateFormat);
    return moment.duration(newEnd.diff(newStart)).asHours();
}

// 0 is regular, 1 is nightly and 2 is mixed
//export const getValidHoursBySchedule = (scheduleType) => (scheduleType === 0 ? 8 : 7);
/**
 * Returns the maximum amount of hours in a schedule according to the schedule type
 * (full or partial) and the type of shift (Day, night and mixed)
 * @param  {number} scheduleType  must be either FULL_SHIFT or PARTIAL_SHIFT
 * @param {number} shiftType must be DAY_SCHEDULE, NIGHT_SCHEDULE or MIXED_SCHEDULE
 *
 * */
export function getValidHoursBySchedule(scheduleType, shiftType) {
    console.table({
        scheduleType,
        shiftType,
        DAY_SCHEDULE,
        PARTIAL_SHIFT,
        NIGHT_SCHEDULE
    });
    //all partial shifts return the same amount of hours
    if (shiftType === PARTIAL_SHIFT) return MAX_HOURS_PARTIAL_SHIFT;
    if (scheduleType === DAY_SCHEDULE) return MAX_HOURS_FULL_SHIFT_DAY_SCHEDULE; //8
    if (scheduleType === NIGHT_SCHEDULE || scheduleType === MIXED_SCHEDULE)
        return MAX_HOURS_FULL_SHIFT_NIGHT_OR_MIXED_SCHEDULE; //7

    throw new Error(`Invalid value as parameter`);
}

export function maximumAmountOfNightHoursBySchedule(scheduleType) {
    if (scheduleType === DAY_SCHEDULE)
        return MAXIMUM_AMOUNT_OF_NIGHT_HOURS_IN_A_DAY_SCHEDULE;
    if (scheduleType === MIXED_SCHEDULE)
        return MAXIMUM_AMOUNT_OF_NIGHT_HOURS_IN_A_MIXED_SCHEDULE;
    throw new Error("passed invalid schedule type");
}

export function maximumAmountOfNightMinutesBySchedule(scheduleType) {
    if (scheduleType === DAY_SCHEDULE) return 0;
    if (scheduleType === MIXED_SCHEDULE) return 240;
    if (scheduleType === NIGHT_SCHEDULE) return 99999999;
    throw new Error("passed invalid schedule type");
}

export function fixNumber(n, fractionDigits = 1) {
    if (parseFloat(n) % 1 === 0) return `${n}`;
    return parseFloat(n).toFixed(fractionDigits);
}
export default function setLocalStorageToken(token) {
    localStorage.setItem("mainUserData", JSON.stringify({ currentToken: token }));
}

const objHasKeys = (obj) => !!Object.values(obj).length;
/**
 * Will recursively check if the object keys contains the value to remove. if
 * the do not it will add them into a new object
 * @param {*} obj  the object   to clean,
 * @param {*} valueToRemove
 */
export const cleanEmptyValuesFromNestedObject = (obj, valueToRemove = "") => {
    return Object.entries(obj).reduce((acc, item) => {
        const [key, value] = item;
        if (value !== valueToRemove) {
            if (typeof value === "object") {
                const objectWithoutEmptyValues = cleanEmptyValuesFromNestedObject(
                    value,
                    valueToRemove
                );
                if (objHasKeys(objectWithoutEmptyValues))
                    acc[key] = objectWithoutEmptyValues;
            } else acc[key] = value;
        }
        return acc;
    }, {});
};

export function cleanObject(obj) {
    return Object.entries(obj).reduce((acc, item) => {
        const [key, value] = item;
        if (typeof value === "object") {
            acc[key] = cleanObject(value);
        } else acc[key] = "";

        return acc;
    }, {});
}
/**
 * Will recursively check if the object keys contains the value to remove. if
 * the do not it will add them into a new object
 *
 * Ideal for flat objects
 * @param {*} obj  the object   to clean,
 * @param {*} valueToRemove
 */
export const cleanRegularObject = (obj) => {
    return Object.entries(obj).reduce((acc, item) => {
        const [key, value] = item;
        if (value !== "") acc[key] = value;
        return acc;
    }, {});
};

export const getIdOfApprovedPayments = (ids, items) =>
    ids
        .map((id) => (items[id].paymentStatus !== APPROVED_PAYMENT ? id : null))
        .filter((v) => v !== null);

export const deepClone = (data) => JSON.parse(JSON.stringify(data));
export const cleanEmptyValuesFromNestedArray = (arr) => {
    return arr.map((v) => cleanEmptyValuesFromNestedObject(v)).filter(objHasKeys);
};

export function deleteKeys(data, ...keysToClean) {
    const cleaner = (obj) =>
        Object.entries(obj).reduce((acc, item) => {
            const [key, value] = item;
            if (!keysToClean.includes(key)) acc[key] = value;
            return acc;
        }, {});

    if (Array.isArray(data)) return data.map((v) => cleaner(v));

    return cleaner(data);
}

export function getSelectData(data) {
    return data.map((v, i) => ({ label: v, value: i }));
}

export function looper(start, end) {
    let acc = [];
    for (let i = start; i <= end; i++) acc.push(i);
    return acc;
}

export const getUniqueValues = (arr) => Array.from(new Set(arr));
