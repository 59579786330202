import React from "react";
import LabeledSelect from "../inputs/select/LabeledSelect";
import { formatDate } from "../../utils/functions";
import RestDaysItem from "./RestDaysItem";
import ButtonContainer from "../../views/app-main/workers/register-worker1/ButtonContainer";
import moment from "moment";
import {
    getValidHoursBySchedule,
    maximumAmountOfNightMinutesBySchedule
} from "../../utils/functions";

import { fullDateFormat } from "../../utils/timeConstants";
import { DAY_SCHEDULE, MIXED_SCHEDULE, TODAY } from "../../utils/constants";
import {
    countPeriodDayHours,
    countPeriodNightHours,
    getDuration
} from "../../utils/timeFunctions";

function checkScheduleValidity(schedules, { shiftType, scheduleType }) {
    const validHoursBySchedule = getValidHoursBySchedule(scheduleType, shiftType);
    return schedules.filter((v) => {
        const { start_time, end_time, rest_hours } = v.value;
        let today = TODAY.format("YYYY-MM-DD");

        const startFormated = `${today} ${start_time}`;
        const endFormatted = `${today} ${end_time}`;

        const startFormatedM = moment(`${today} ${start_time}`, fullDateFormat);
        const endFormattedM = moment(`${today} ${end_time}`, fullDateFormat);

        if (startFormatedM.isAfter(endFormattedM)) endFormattedM.add(1, "d");

        const duration =
            getDuration(startFormated, endFormatted, fullDateFormat) - rest_hours;
        const schedulesHasInvalidDuration = duration > validHoursBySchedule;

        let nightime = countPeriodNightHours(
            startFormatedM.format(fullDateFormat),
            endFormattedM.format(fullDateFormat)
        );

        const totalDayMinutes = countPeriodDayHours(startFormated, endFormatted);
        const isDayOrMixedSchedule =
            scheduleType === DAY_SCHEDULE || scheduleType === MIXED_SCHEDULE;
        const isValid = isDayOrMixedSchedule
            ? nightime <= maximumAmountOfNightMinutesBySchedule(scheduleType)
            : totalDayMinutes <= 180;

        return isValid && !schedulesHasInvalidDuration;
    });
}

const weekDays = [
    { label: "Lunes", name: 1 },
    { label: "Martes", name: 2 },
    { label: "Miércoles", name: 3 },
    { label: "Jueves", name: 4 },
    { label: "Viernes", name: 5 },
    { label: "Sábado", name: 6 },
    { label: "Domingo", name: 0 }
];
const WeekInputs = (props) => {
    const {
        selectedDinamicSchedules,
        setScheduleWeek,
        scheduleType,
        shiftType,
        ids,
        schedules,
        items,
        setScheduleRestDays,
        restDaysValues,
        addScheduleModal,
        schedulesValid
    } = props;

    const dinamicScheduleData1 = ids.map((id) => {
        const { end_time: endTime, start_time: startTime, rest_hours: restHours } = items[
            id
        ];
        const formatTime = (date) => formatDate(date, "hh:mm A", "HH:mm:ss");
        const startFormatted = formatTime(startTime);
        const endFormatted = formatTime(endTime);
        const label = `${startFormatted} - ${endFormatted} ${restHours}h de descanso `;
        return { label, id, value: items[id], isDinamicSchedule: true };
    });

    const dinamicScheduleData = checkScheduleValidity(dinamicScheduleData1, {
        scheduleType,
        shiftType
    });

    const selectData = schedules.map((v, index) => {
        const format = (d) => `${formatDate(d, "hh:mm A", "HH:mm")}`;
        const label = `${format(v.start_time)} - ${format(v.end_time)} ${
            v.rest_hours
        }h de descanso`;

        return {
            index,
            label,
            value: index,
            scheduleData: v
        };
    });
    //const scheduleValuesArray = Object.values(scheduleValues);

    const selectedNewWeekDays = Object.keys(schedulesValid).reduce((acc, item) => {
        acc[item] = true;
        return acc;
    }, {});

    const selectedOldWeekDays = Object.keys(selectedDinamicSchedules).reduce(
        (acc, item) => {
            acc[item] = true;
            return acc;
        },
        {}
    );

    const selectItems = weekDays.map((elem, index) => {
        const { name: weekDay } = elem;
        let value = "";

        if (selectedNewWeekDays[weekDay]) value = schedulesValid[weekDay];
        if (selectedOldWeekDays[weekDay])
            value = items[selectedDinamicSchedules[weekDay]];
        console.log(selectedOldWeekDays, selectedNewWeekDays, weekDay, 33);
        return (
            <LabeledSelect
                {...elem}
                callback={setScheduleWeek}
                index={index}
                value={value}
                key={elem.name}
                //selectData={dinamicScheduleData1}
                selectData={[...selectData, ...dinamicScheduleData]}
                layoutSpacingClassName="worker-schedule-input-item"
            />
        );
    });

    const allItems = [
        ...selectItems,
        <RestDaysItem
            setScheduleRestDays={setScheduleRestDays}
            restDaysValues={restDaysValues}
        />
    ];

    return (
        <div className="weekDays-super">
            <h4 className="main-area-subtitle"> Horario </h4>
            <div className="weekDays-items">{allItems}</div>
            <ButtonContainer
                containerClassName="register-worker-row-button-container"
                buttonCallback={addScheduleModal}
                buttonText="Nuevo Horario"
            />
        </div>
    );
};

WeekInputs.defaultProps = {
    selectData: [
        {
            label: "Primer Horario",
            value: 0
        },
        {
            label: "Segundo Horario",
            value: 1
        }
    ]
};

export default WeekInputs;
