export const setCachedData = ({ endpoint, ids, actualPage, items, additionalData }) => {
    return { type: "SET_CACHED_DATA", ids, items, additionalData, endpoint, actualPage };
};

export const setCachedDataById = ({ endpoint, id, data }) => {
    return {
        type: "SET_CACHED_DATA_BY_ID",
        endpoint,
        id,
        data
    };
};

export const deleteCachedData = (endpoint) => {
    return {
        type: "DELETE_CACHED_DATA",

        endpoint
    };
};
export const deleteCachedDataById = ({ endpoint, id }) => {
    return {
        type: "DELETE_CACHED_DATA_BY_ID",
        endpoint,
        id
    };
};
export const deleteAllCachedData = () => {
    return {
        type: "DELETE_ALL_CACHED_DATA"
    };
};
