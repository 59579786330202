import moment from "moment";

/**
 * Transforms the value of an input while the user is
 * @param {string} transform
 * @param {string} value
 */
function inputTransform(transform, value) {
    switch (transform) {
        case "uppercase":
            return value.toUpperCase();
        case "date":
            const isDateValid = moment(value, "YYYY-MM-DD", true).isValid();
            if (!isDateValid) {
                const invalidDate = moment(value, "DD/MM/YYYY", true);
                if (invalidDate.isValid()) return invalidDate.format("YYYY-MM-DD");
            }
            return value;
        case "local-currency":
            //const numValue = value.match(/\d+/);
            //FIX
            //needs a  regex
            return value;
        case "bank-account-number":
            if (value.length >= 20 /* || /\D+/g.test(value.replace(/-/g, "") */)
                return value.substring(0, value.length - 1);
            return value
                .replace(/-/g, "")
                .split("")
                .map((v, i) => `${i % 4 === 0 && i !== 0 ? "-" : ""}${v}`)
                .join("");
        case "bank-account-raw":
            return value.replace(/-/g, "");
        case "rif":
            return `J${value.replace(/J/g, "")}`;
        default:
            return value;
    }
}

export default inputTransform;
