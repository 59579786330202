import React from "react";
import { Route, Switch } from "react-router-dom";
import appMainRoutes from "../../routes";
import { Suspense } from "react";
import Loader from "../../global-components/loader/Loader";

const CenterCardAuthSwitch = (props) => {
    const { setLoggedUser, toggleLoading, pathname } = props;
    return (
        <div className="center-card-pad">
            <div className="center-card-container">
                <form>
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            {appMainRoutes.centerCard.public.map((elem) => {
                                const { component: Component } = elem;
                                return (
                                    <Route
                                        key={elem.path}
                                        path={elem.path}
                                        exact={elem.exact}
                                        setLoggedUser={setLoggedUser}
                                        render={(props) => (
                                            <Component
                                                {...props}
                                                pathname={pathname}
                                                setLoggedUser={setLoggedUser}
                                                toggleLoading={toggleLoading}
                                            />
                                        )}
                                    />
                                );
                            })}
                        </Switch>
                    </Suspense>
                </form>
                {/*  </form> */}
            </div>
        </div>
    );
};

export default CenterCardAuthSwitch;
