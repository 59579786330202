const restDaysSelectData = [
    {
        label: "Lunes-Martes",
        value: 0,
        day1: 1,
        day2: 2
    },
    {
        label: "Martes-Miércoles",
        value: 2,
        day1: 2,
        day2: 3
    },
    {
        label: "Miércoles-Jueves",
        value: 3,
        day1: 3,
        day2: 4
    },
    {
        label: "Jueves-Viernes",
        value: 4,
        day1: 4,
        day2: 5
    },
    {
        label: "Viernes-Sábado",
        value: 5,
        day1: 5,
        day2: 6
    },
    {
        label: "Sábado-Domingo",
        value: 6,
        day1: 6,
        day2: 0
    },
    {
        label: "Domingo-Lunes",
        value: 7,
        day1: 0,
        day2: 1
    }
];

export default restDaysSelectData;
