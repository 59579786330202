import React from "react";
import LabeledSelect from "../inputs/select/LabeledSelect";
import restDaysSelectData from "./restDaysSelectData";

const RestDaysItem = ({ setScheduleRestDays, restDaysValues }) => {
    let value = null;
    try {
        if (restDaysValues.length && restDaysValues[0] === 0 && restDaysValues[1] === 6) {
            value = restDaysSelectData[5].value;
        } else {
            value = restDaysValues.length
                ? restDaysSelectData.find(
                      (v) => v.day1 === restDaysValues[0] && v.day2 === restDaysValues[1]
                  ).value
                : "";
        }
    } catch {
        console.error("error");
    }
    return (
        <LabeledSelect
            label="Días de descanso"
            name="restHours"
            //isDisabled={areSelectsValid}
            layoutSpacingClassName="worker-schedule-input-item"
            callback={setScheduleRestDays}
            selectData={restDaysSelectData}
            value={value}
        />
    );
};

export default RestDaysItem;
