import * as React from "react";

function SvgRemove(props) {
    return (
        <svg height={24} width={24} {...props}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 13H5v-2h14v2z" />
        </svg>
    );
}

export default SvgRemove;
