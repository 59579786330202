import React, { Component, Fragment } from "react";
import "../../styles/CSS/global-components/schedule-picker.css";
import WeekInputs from "./WeekInputs";
import { popNotification } from "../../redux/actions/popNotificationsActions";
import { connect } from "react-redux";
import { uniqueId } from "../../utils/functions";
import { addScheduleModal } from "../../redux/actions/modalActions";

const mapDispatchToProps = (dispatch) => {
    return {
        popNotification: (notificationText, type) =>
            dispatch(popNotification(notificationText, type)),
        addScheduleModal: (callback) => dispatch(addScheduleModal(callback))
    };
};

/**
 * Container of the HoursInputs, WeekDaysInputs and the RestDayInputs.
 * 
 * @method setSchedule adds the hours values to 
 * @method addSchedule handles adding another schedule
 * @method setScheduleRestDays onChange method for the restDays select
 * 
 *  @method checkScheduleValidity handles the final validation by checking if the HoursInputs have valid hours and the DaysInputs and RestDays have selected values. If they do it will add the restDays key to the schedules and fire the request method
 * 


 */
class SchedulePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schedulesAmount: 1,
            update: true
        };
        this.schedulesAmount = 1;
        this.addSchedule = this.addSchedule.bind(this);
    }

    componentDidMount() {
        const { ids, items } = this.props.data;
        const schedules = ids.map((v) => items[v]);
        console.log(this.props.setDinamicSchedules, 777);
        this.props.setDinamicSchedules(schedules, this.props.data);
    }

    addSchedule() {
        const {
            props: { popNotification }
        } = this;
        if (this.schedulesAmount > 6) {
            popNotification("No es posible ingresar más horarios", "warning");
            return;
        }

        const lastSchedule = this.props.schedule.schedules[
            this.props.schedule.schedules.length - 1
        ];
        const { start_time, rest_hours, end_time } = lastSchedule;
        const lastScheduleData = { start_time, rest_hours, end_time };
        const lastScheduleHasAllFields = Object.values(lastScheduleData)
            .map((elem) => elem !== null)
            .every((v) => v);

        if (!lastScheduleHasAllFields) {
            popNotification(
                "Llene el campo de horario anterior antes de ingresar otro",
                "warning"
            );
            return;
        }

        this.props.setSchedule({
            index: this.schedulesAmount,
            rest_hours: null,
            end_time: null,
            start_time: null
        });
        this.schedulesAmount = this.schedulesAmount + 1;

        this.setState({
            update: uniqueId()
        });
    }

    render() {
        const {
            addSchedule,
            props: {
                addScheduleModal,
                setSchedule,
                setScheduleWeek,
                setScheduleRestDays,
                data: { ids, items },
                schedule: {
                    schedules: hoursInputsValues,
                    restDays,
                    schedulesValid,
                    selectedDinamicSchedules
                },
                scheduleValues: { contract_condition, schedule_type, shift_type }
            }
        } = this;
        const bcb = {
            setSchedule,
            hoursInputsValues,
            itemsAmount: this.schedulesAmount,
            setScheduleRestDays,
            addItemCallback: addSchedule,
            contractCondition: contract_condition,
            scheduleType: schedule_type,
            shiftType: shift_type
        };
        const addModal = () => addScheduleModal(bcb);
        return (
            <Fragment>
                <div className="schedule-picker-super  fade-in">
                    <div className="schedule-picker-container">
                        <WeekInputs
                            schedulesAmount={this.schedulesAmount}
                            setScheduleWeek={setScheduleWeek}
                            ids={ids}
                            items={items}
                            scheduleValues={{
                                ...schedulesValid,
                                ...selectedDinamicSchedules
                            }}
                            selectedDinamicSchedules={selectedDinamicSchedules}
                            schedulesValid={schedulesValid}
                            addScheduleModal={addModal}
                            scheduleType={schedule_type}
                            shiftType={shift_type}
                            schedules={this.props.schedule.schedules}
                            //      setDay={setDay}
                            setScheduleRestDays={setScheduleRestDays}
                            restDaysValues={restDays}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default connect(null, mapDispatchToProps)(SchedulePicker);
