import * as React from "react";

function SvgPercentageUp(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 11" fill="none" {...props}>
            <path
                d="M1 10l9.257-9L19 10"
                stroke="#33D9B2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default SvgPercentageUp;
