import * as React from "react";

function SvgPercentageNeutral(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 10 19" fill="none" {...props}>
            <path
                d="M.5.5l9 9.257-9 8.743"
                stroke="#BDBDBD"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default SvgPercentageNeutral;
