import React from "react";
import Ajax from "./Ajax";
import RequestError from "../global-components/layout/request-error/index";

import { connect } from "react-redux";
import DefaultLoader from "../global-components/default-loader/DefaultLoader";
import { setCachedData } from "../redux/actions/cacheActions";
import PropTypes from "prop-types";
import DefaultError from "../global-components/default-error/DefaultError";

const mapDispatchToProps = (dispatch) => {
    return {
        setCachedData: (payload) => dispatch(setCachedData(payload))
    };
};

function mapStateToProps(state) {
    return {
        cacheReducer: state.cacheReducer
    };
}

class CacheableWithRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            requestData: {},
            hasError: false
        };
        this.errorData = null;
        this.userId = null;
        this.makeRequest = this.makeRequest.bind(this);
        this.resetData = this.resetData.bind(this);
        //     this.showLoaderOnNextRequest = false;
        this.showSpinner = true;
        this.request = false;
        this.actualPage = 0;
        this.onRequest = false;
        this.requests = [null, null];
        this.itemsData = {
            ids: [],
            items: {}
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.endpoint !== prevProps.endpoint) {
            this.itemsData = {
                ids: [],
                items: {}
            };
            this.setState({ isLoading: true });
            this.makeRequest();
        }
    }

    componentDidMount() {
        this.makeRequest();
    }

    resetData() {
        this.itemsData = {
            ids: [],
            items: {}
        };
        this.actualPage = 0; // ?
    }

    makeRequest() {
        const {
            props: { cacheReducer, endpoint }
        } = this;

        if (cacheReducer[endpoint].actualPage >= this.actualPage) {
            const { actualPage, ids, items, ...data } = cacheReducer[endpoint];

            this.actualPage = actualPage + 1;
            this.itemsData = {
                ids,
                items
            };

            this.onRequest = false;

            this.setState({
                hasError: false,
                requestData: { ids, items, ...data },
                isLoading: false
            });
            return;
        }
        if (this.onRequest === true) return;
        this.onRequest = true;

        if (
            this.actualPage === 0 &&
            !this.state.isLoading
            //this.showLoaderOnNextRequest
        ) {
            this.setState({ isLoading: true });
        }
        const xhr1 = new Ajax(this.props.endpoint, { useBaseUrl: true });
        this.requests = xhr1;
        xhr1.result()
            .then((res) => {
                this.actualPage = this.actualPage + 1;

                const { ids, data, ...additionalData } = res;
                const requestIds = (ids || []).map(Number);
                this.props.setCachedData({
                    ids: [...this.itemsData.ids, ...requestIds],
                    items: {
                        ...this.itemsData.items,
                        ...data
                    },
                    ...data,

                    endpoint: this.props.endpoint,
                    actualPage: this.actualPage,
                    additionalData
                });
                this.itemsData = {
                    ids: [...this.itemsData.ids, ...requestIds],
                    items: {
                        ...this.itemsData.items,
                        ...data
                    },
                    ...data
                };

                this.onRequest = false;

                this.setState({
                    hasError: false,
                    requestData: res,
                    isLoading: false
                });
            })

            .catch((err) => {
                console.log(err, "error");
                this.errorData = err;
                this.itemsData = {
                    ids: null,
                    items: {}
                };
                this.onRequest = false;
                this.fullEndpoint = false;

                this.setState({
                    hasError: true,
                    requestData: false,
                    isLoading: false
                });
            })
            .finally(() => (this.requests = null));
    }

    render() {
        const {
            itemsData,
            makeRequest,
            resetData,
            showSpinner,
            onRequest,
            actualPage,
            props: {
                endpoint,
                cacheReducer,
                LoaderComponent,
                Component,
                layoutClassName,
                ErrorComponent,
                loaderProps,
                hasSpinner,
                ...otherProps
            },
            state: { isLoading, hasError, requestData }
        } = this;

        const resetDataAndMakeRequest = () => {
            resetData();
            makeRequest();
        };

        if (isLoading) {
            return <LoaderComponent layoutClassName={layoutClassName} {...loaderProps} />;
        }
        if (hasError) {
            return (
                <RequestError
                    layoutClassName={layoutClassName}
                    errorData={this.errorData}
                    buttonCallback={resetDataAndMakeRequest}
                    containerClassName={`${layoutClassName} grid-center `}
                />
            );
        }

        return (
            <Component
                data={itemsData}
                showSpinner={showSpinner}
                spinnerCallback={makeRequest}
                onRequest={onRequest}
                actualPage={actualPage}
                requestData={requestData}
                /*   m1={cacheReducer[endpoint]}
                m2={cacheReducer["payment-chart-data"].ids} */
                {...otherProps}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CacheableWithRequest);

CacheableWithRequest.defaultProps = {
    layoutClassName: "",
    otherProps: {},
    requestParams: {},
    LoaderComponent: DefaultLoader,
    ErrorComponent: DefaultError
};

CacheableWithRequest.propTypes = {
    // Component: PropTypes.element.isRequired,
    layoutClassName: PropTypes.string,
    otherProps: PropTypes.object
};
