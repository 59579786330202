const invalidStartDateReducer = (currentState = { isValid: true }, action) => {
    switch (action.type) {
        case "SET_START_DATE_VALIDITY":
            return { isValid: action.isValid };

        default:
            return currentState;
    }
};

export default invalidStartDateReducer;
