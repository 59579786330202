import React from "react";
import Spinner from "../loader/LoaderSmall";

const DefaultLoader = ({ layoutClassName }) => (
    <div className={`${layoutClassName} grid-center`}>
        <Spinner />
    </div>
);

export default DefaultLoader;
