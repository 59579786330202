import React from "react";
import Spinner from "../../media/icons/SpinnerSmall";

const LoaderSmall = (props) => {
    const { containerClassName } = props;
    return (
        <div className={`grid-center loader-small ${containerClassName} `}>
            <Spinner />
        </div>
    );
};

export default LoaderSmall;

LoaderSmall.defaultProps = {
    containerClassName: ""
};
