import React, { memo } from "react";
import "../../styles/CSS/global-components/sidebar-style.css";
import SideBarCompany from "./SideBarCompany";
import ItemsContainer from "./ItemsContainer";
import SidebarLogout from "./SidebarLogout";
import { sidebarToggle } from "../../redux/actions/sidebarActions";
import { connect } from "react-redux";

function mapStateToProps(state) {
    return {
        showSidebar: state.sidebarReducer.showSidebar
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        sidebarToggle: () => dispatch(sidebarToggle())
    };
};

const SideBar = memo((props) => {
    const { sidebarToggle, showSidebar, logoutUser, path } = props;
    const containerClass = `sidebar-container-super${showSidebar ? "_active" : ""}`;

    function check1(e) {
        const MOBILE_MENU_MIN_SIZE = 1000;
        if (window.innerWidth <= MOBILE_MENU_MIN_SIZE) {
            const isValidTarget =
                /sidebar-company/.test(e.target.className) === false &&
                /sidebar-log-out-container/.test(e.target.className) === false;
            if (isValidTarget) {
                sidebarToggle();
            }
        }
    }
    return (
        <div className={containerClass} onClick={(e) => check1(e)}>
            <div
                className={`sidebar-container ${
                    showSidebar ? "sidebar-mobile_open" : ""
                }`}
            >
                <SideBarCompany />
                <ItemsContainer path={path} />
                <SidebarLogout logout={logoutUser} />
            </div>
        </div>
    );
});
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
