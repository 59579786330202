const defaultState = {
    workExperienceDataState: [""],
    personalReferencesDataState: [""],
    bankAccountCodes: [""]
};

const registerWorkerFrontDataReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_VALUE":
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default registerWorkerFrontDataReducer;
