import * as React from "react";

function SvgArrowLeft(props) {
    return (
        <svg width={30} height={30} fill="none" {...props}>
            <path
                d="M15 0l2.645 2.645L7.18 13.129H30v3.752H7.181l10.464 10.474L15 30 0 15 15 0z"
                fill="#828282"
            />
        </svg>
    );
}

export default SvgArrowLeft;
