import React from "react";
import Spinner from "../../media/icons/SpinnerSmall";

const Loader = (props) => {
    return (
        <div className="grid-center lazy-loader-container">
            <Spinner />
        </div>
    );
};

export default Loader;
