import * as React from "react";

function SvgExpenses(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
            <path d="M18.947 16.009v1.059a2.118 2.118 0 01-2.105 2.117H2.105A2.11 2.11 0 010 17.068V2.246A2.11 2.11 0 012.105.13h14.737c1.158 0 2.105.953 2.105 2.117v1.06H9.474a2.11 2.11 0 00-2.106 2.116v8.47a2.11 2.11 0 002.106 2.117h9.473zm-9.473-2.117H20v-8.47H9.474v8.47zm4.21-2.647c-.873 0-1.579-.71-1.579-1.588 0-.879.705-1.588 1.58-1.588.873 0 1.578.71 1.578 1.588 0 .879-.705 1.588-1.579 1.588z" />
        </svg>
    );
}

export default SvgExpenses;
