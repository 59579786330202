import React from "react";
import inputValidator from "../../utils/InputValidator";
import inputTransform from "../../utils/inputTransform";
import Toolbar from "./Toolbar";
import "../../styles/CSS/global-components/inputs.css";
import getTransformedValue from "../../utils/getTransformedValue";

class InputRegular extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            update: true
        };
        this.getValue = this.getValue.bind(this);
        this.value = this.props.value ? this.props.value : "";

        this.isValid = this.props.value
            ? inputValidator(this.props.validationType, this.props.value)
            : this.props.forceInvalid
            ? false
            : true || true;
    }
    componentDidUpdate(prev) {
        const { didUpdateCallback, didUpdateKey } = this.props;
        if (didUpdateCallback) {
            didUpdateCallback(this[didUpdateKey]);
        }

        //        console.log(this.props.name, "name")
    }

    getValue(e) {
        const {
            props: {
                inputTransform: inputTransformType,
                inputData,
                validationType,
                callback,
                invalidDataCallback,
                sendAsDifferentValue
            }
        } = this;
        this.value = inputTransformType
            ? inputTransform(inputTransformType, e.target.value)
            : e.target.value;
        this.isValid = inputValidator(validationType, this.value);
        // ######### validation on

        if (this.isValid) {
            if (sendAsDifferentValue) {
                callback({
                    ...inputData,
                    value: getTransformedValue(this.value, sendAsDifferentValue)
                });
            } else {
                callback({ ...inputData, value: this.value });
            }
        } else {
            callback({ ...inputData, value: "", invalid: 1 });
        }
        if (invalidDataCallback) invalidDataCallback({ ...inputData, value: this.value });
        this.setState({ update: true });
    }

    render() {
        const {
            isValid,
            getValue,
            value,
            props: {
                name,
                placeholder,
                type,
                autocomplete,
                className,
                validationType,
                isDisabled,
                //  maxLength,
                min,
                inputElementProps
            }
        } = this;
        const elemClassName = ` input ${isValid ? "" : "input_invalid"} ${
            className || ""
        } ${isDisabled ? "input_disabled" : ""}`;
        return (
            <div
                className={`input-container ${isValid ? "" : "input-container-invalid"}`}
            >
                <input
                    {...inputElementProps}
                    name={name ? name : ""}
                    className={elemClassName}
                    placeholder={placeholder}
                    type={type}
                    onChange={getValue}
                    value={value}
                    autoComplete={autocomplete}
                    disabled={isDisabled}
                    maxLength={inputElementProps.maxLength}
                    min={min}
                />
                <Toolbar display={!this.isValid} validationType={validationType} />
            </div>
        );
    }
}

export default InputRegular;
