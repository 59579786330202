const defaultState = {
    languages: [],
    cities: {},
    payrolls: [],
    banks: [],
    phoneCodes: [],
    states: []
};

const dinamicSelectsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_REGISTER_WORKER_DINAMIC_SELECT_DATA":
            return { ...state, ...action.payload };
        case "SET_REGISTER_WORKER_DINAMIC_SELECT_CITIES_DATA":
            return {
                ...state,
                cities: { ...state.cities, [action.cityValue]: action.cities }
            };
        case "RESET_DINAMIC_SELECTS_DATA":
            return {
                languages: [],
                cities: {},
                payrolls: [],
                banks: [],
                phoneCodes: [],
                states: []
            };
        default:
            return state;
    }
};

export default dinamicSelectsReducer;
