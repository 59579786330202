export const showInputsModalAction = "SHOW_ADD_INPUTS_MODAL";

export const modalToggle = (payload) => {
    return { type: "TOGGLE_MODAL", payload };
};

export const closeModal = () => {
    return { type: "CLOSE_MODAL", title: null, elementType: null, items: null };
};

export const showSpinner = () => {
    return { type: "SHOW_MODAL_SPINNER" };
};

export const showAssistanceInputs = (payload) => {
    return { type: "SHOW_ASSISTANCE_INPUTS", items: payload.items };
};

export const showAddInputsModal = () => {
    return { type: showInputsModalAction };
};

export const showLocalWorkerDataModal = (payload, message) => {
    return { type: "REGISTER_WORKER", items: payload, message };
};

export const addScheduleModal = (payload) => {
    return { type: "ADD-SCHEDULE", items: payload };
};
