import React, { Suspense } from "react";
import Loader from "../../global-components/loader/Loader";
import { Route, Switch } from "react-router-dom";
import appMainRoutes from "../../routes";
import { connect } from "react-redux";
import InitialErrorHandler from "./InitialErrorHandler";

/**
 * The main router inside the app. It contains every route inside
 * appMainRoutes.If it doesn't find a route it will return a 404.
 *
 * Set requestEror is a method to display a full page error instead of
 * component based ones when every request inside the view fails.
 * We didn't add it but all the components are there.
 *
 *
 */

function mapStateToProps(state) {
    return {
        userData: state.userSessionReducer.user
    };
}

class MainRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: null
        };
        this.setRequestError = this.setRequestError.bind(this);
        this.cleanRequestError = this.cleanRequestError.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.pathname !== prevProps.pathname) {
            this.cleanRequestError();
        }
    }

    cleanRequestError() {
        if (this.state.hasError) {
            this.setState({
                hasError: null
            });
        }
    }

    setRequestError(errorData) {
        this.setState({
            hasError: errorData
        });
    }
    render() {
        const {
            //   setError,
            props: { url, userData },
            cleanRequestError
        } = this;
        const userLoggedCorrectly = userData;

        if (!userLoggedCorrectly) {
            return <InitialErrorHandler />;
        }
        return (
            <Suspense fallback={<Loader />}>
                <Switch>
                    {appMainRoutes.appMain.map((elem) => {
                        const { component: Component } = elem;
                        return (
                            <Route
                                path={`${url}/${elem.path}`}
                                key={elem.path}
                                exact={elem.exact}
                                render={(props) => (
                                    <Component
                                        {...props}
                                        key={elem.path}
                                        cleanRequestError={cleanRequestError}
                                        setRequestError={this.setRequestError}
                                    />
                                )}
                            />
                        );
                    })}
                </Switch>
            </Suspense>
        );
    }
}

export default connect(mapStateToProps)(MainRouter);
