import React from "react";
import ModalBtn from "./ModalBtn";

const ModalITemsContainer = ({ items }) => {
    return (
        <div className="flex-col-align-center">
            {items.map((elem) => (
                <ModalBtn title={elem.title} key={elem.title} route={elem.route} />
            ))}
        </div>
    );
};

export default ModalITemsContainer;
